import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { fetchAllStores } from "../api/elasticDataApi";
import { IStore } from "../interfaces/IStore";
import { AxiosError } from "axios";

export function useElasticData(): UseMutationResult<IStore[], Error, void> {
  const queryClient = useQueryClient();

  return useMutation<IStore[], Error, void>(
    () => fetchAllStores(),
    {
      retry: 2,
      onSuccess: (data) => {
        console.debug("useElasticData onSuccess", data.length);
        queryClient.setQueryData(["elasticData"], data);
      },
      onError: (error: AxiosError) => {
        console.error("useElasticData onError:", error.message);
        if (error.response) {
          console.error("Server response:", error.response.data);
          console.error("Status code:", error.response.status);
          console.error("Headers:", error.response.headers);
        } else if (error.request) {
          console.error("No response received:", error.request);
        } else {
          console.error("Error details:", error.toJSON());
        }
      },
    }
  );
}