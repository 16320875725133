import { AxiosError } from "axios";
import { enqueueSnackbar } from "notistack";
import { useEffect, useRef } from "react";
import { useWatch } from "react-hook-form";
import { useShallow } from 'zustand/react/shallow';
import {
  useCreateMarketMoveRun,
  useGetSingleMMRun,
  useUpdateMarketMoveRun,
} from "../hooks/marketMoveRunApiHooks";
import { RunStatus } from "../interfaces/enums";
import {
  MarketMoveMetaDataSchema,
  MarketMoveProductConfigSchema,
  MarketMoveRunSchema,
} from "../schema/schemas";
import {
  useFormActions,
  useMarketMoveMetaDataMethods,
  useMarketMoveRunMethods,
  usePreviewDetailsMethods,
} from "../store/formStore";
import useGeneralStore from "../store/generalStore";
import useMarketMoveStore, { MMRunSaveRequest } from "../store/marketMoveStore";
import { deriveConfigFromRecap, generateDefaultMarketMoveRun } from "../utils/marketMoveUtils";
import { useGetMMList } from "./marketMoveMetaDataApiHooks";

export const useMarketMoveRun = () => {
  const { setShowPreview } = useGeneralStore();
  const {
    mmRunSaveRequest,
    setSaveOnRecap,
    setMarketMoveRunSubmitReady,
    setDisableEditing
  } = useMarketMoveStore(
    useShallow(state => ({
      mmRunSaveRequest: state.mmRunSaveRequest,
      setSaveOnRecap: state.setSaveOnRecap,
      setMarketMoveRunSubmitReady: state.setMarketMoveRunSubmitReady,
      setDisableEditing: state.setDisableEditing
    }))
  );
  const createMarketMoveRun = useCreateMarketMoveRun();
  const updateMarketMoveRun = useUpdateMarketMoveRun();

  

  const {
    control: marketMoveRunControl,
    reset: marketMoveRunReset,
    setValue: marketMoveRunSetValue,
  } = useMarketMoveRunMethods();
  const marketMoveRun = useWatch<MarketMoveRunSchema>({
    control: marketMoveRunControl,
  });

  const { control: marketMoveMetaDataControl, trigger } =
    useMarketMoveMetaDataMethods();
  const marketMoveMetaData = useWatch<MarketMoveMetaDataSchema>({
    control: marketMoveMetaDataControl,
  });

  const { reset: previewReset } = usePreviewDetailsMethods();
  const { getStoreProductConfig, setOriginalMarketMoveRunRecapDetails } = useFormActions();
  const { data, isFetching, error, refetch } = useGetSingleMMRun(
    marketMoveMetaData?.marketMoveRunId
  );
  const { refetch: refetchMMList } = useGetMMList(undefined, {
    enabled: false,
  });

  // Refs to manage polling intervals and timeouts
  const pollingIntervalsRef = useRef<{ [key: string]: NodeJS.Timeout | null }>(
    {}
  );
  const pollingTimeoutsRef = useRef<{ [key: string]: NodeJS.Timeout | null }>(
    {}
  );

  // Generic function to start polling
  const startPolling = (key: string) => {
    if (!pollingIntervalsRef.current[key]) {
      console.debug(
        `[useMarketMoveRun] - Starting polling interval for ${key}`
      );
      pollingIntervalsRef.current[key] = setInterval(() => {
        console.debug(`[useMarketMoveRun] - Polling (${key}): Refetching data`);
        refetch();
      }, 15000); // Poll every 15 seconds
    }
  };

  // Generic function to stop polling
  const stopPolling = (key: string) => {
    if (pollingIntervalsRef.current[key]) {
      console.debug(
        `[useMarketMoveRun] - Stopping polling interval for ${key}`
      );
      clearInterval(pollingIntervalsRef.current[key]);
      pollingIntervalsRef.current[key] = null;
    }
    if (pollingTimeoutsRef.current[key]) {
      clearTimeout(pollingTimeoutsRef.current[key]);
      pollingTimeoutsRef.current[key] = null;
    }
  };

  useEffect(() => {
    if (marketMoveRun?.marketMoveRun.marketMoveId) {
      const previewDetails = {
        marketMoveId: marketMoveRun?.marketMoveRun?.marketMoveId,
        marketMoveRunId: marketMoveRun?.marketMoveRun?.marketMoveRunId,
        marketMoveName: marketMoveRun?.marketMoveRun?.marketMoveName,
        marketMoveStores: marketMoveMetaData?.storeList,
        marketMoveProductConfigs: mergeProductConfigs(
          marketMoveRun.marketMoveRunProductConfigs,
          marketMoveMetaData?.productConfigList
        ),
        storeProductConfig: deriveConfigFromRecap({ recapDetails: marketMoveRun?.marketMoveRunRecapDetails, selectedMarketMoveStoreList: marketMoveMetaData?.storeList }),
      }
      console.debug("[useMarketMoveRun] - if - Derived new config from recap details", previewDetails.storeProductConfig);
      previewReset(previewDetails);
    } else {
      previewReset({
        marketMoveId: marketMoveMetaData?.marketMoveId,
        marketMoveRunId: marketMoveMetaData?.marketMoveRunId,
        marketMoveName: marketMoveMetaData?.marketMoveName,
        marketMoveStores: marketMoveMetaData?.storeList,
        marketMoveProductConfigs: marketMoveMetaData?.productConfigList,
        storeProductConfig: [],
      });
      marketMoveRunSetValue("marketMoveRunRecapDetails", []);
    }
    if (marketMoveRun?.marketMoveRunRecapDetails?.length > 0) {
      setShowPreview(true);
    }
  }, [marketMoveRun?.marketMoveRun?.marketMoveId]);

  useEffect(() => {
    if (data) {
      if (
        pollingIntervalsRef.current.startDate &&
        data.marketMoveRun.runStatus !== RunStatus.SIGNEDOFF
      ) {
        refetchMMList();
        console.debug(
          "[useMarketMoveRun] - Refreshing MM List as status is no longer SIGNEDOFF"
        );
        stopPolling("startDate");
      } else if (
        pollingTimeoutsRef.current.endDate &&
        data.marketMoveRun.runStatus !== RunStatus.INPROGRESS
      ) {
        console.debug(
          "[useMarketMoveRun] - Refreshing MM List as status is no longer INPROGRESS"
        );
        marketMoveRunReset(generateDefaultMarketMoveRun());
        refetchMMList();
        stopPolling("endDate");
      }
      if (data.marketMoveRunRecapDetails) {
        console.debug("[useMarketMoveRun] - Storing original marketMoveRunRecapDetails", data.marketMoveRunRecapDetails);
        setOriginalMarketMoveRunRecapDetails([...data.marketMoveRunRecapDetails]);
      }
      marketMoveRunReset(data);
    } else {
      marketMoveRunReset(generateDefaultMarketMoveRun());
      stopPolling("startDate");
      stopPolling("endDate");
      setOriginalMarketMoveRunRecapDetails([]);
    }
    setDisableEditing(data?.marketMoveRun?.runStatus === RunStatus.INPROGRESS);
  }, [data]);

  

  // Polling logic for startDate
  useEffect(() => {
    const key = "startDate";
    const runStatus = data?.marketMoveRun?.runStatus;
    const startDate = data?.marketMoveRun?.startDate;

    const startTime = startDate ? new Date(startDate).getTime() : null;
    const now = Date.now();
    const delayToStart = startTime ? startTime - now : 0;

    if (runStatus === RunStatus.SIGNEDOFF) {
      if (delayToStart > 0) {
        console.debug(
          `[useMarketMoveRun] - Scheduling polling for ${key} to start after ${delayToStart}ms`
        );
        pollingTimeoutsRef.current[key] = setTimeout(
          () => startPolling(key),
          delayToStart
        );
      } else {
        console.debug(
          `[useMarketMoveRun] - ${key} has passed, starting polling immediately`
        );
        startPolling(key);
      }
    } else {
      stopPolling(key);
    }
  }, [data?.marketMoveRun?.runStatus, data?.marketMoveRun?.startDate]);

  // Polling logic for endDate
  useEffect(() => {
    const key = "endDate";
    const runStatus = data?.marketMoveRun?.runStatus;
    const endDate = data?.marketMoveRun?.endDate;

    const endTime = endDate ? new Date(endDate).getTime() : null;
    const now = Date.now();
    const delayToEnd = endTime ? endTime - now : 0;

    if (runStatus === RunStatus.INPROGRESS) {
      if (delayToEnd > 0) {
        console.debug(
          `[useMarketMoveRun] - Scheduling polling for ${key} to start after ${delayToEnd}ms`
        );
        pollingTimeoutsRef.current[key] = setTimeout(
          () => startPolling(key),
          delayToEnd
        );
      } else {
        console.debug(
          `[useMarketMoveRun] - ${key} has passed, starting polling immediately`
        );
        startPolling(key);
      }
    } else {
      stopPolling(key);
    }
  }, [data?.marketMoveRun?.runStatus, data?.marketMoveRun?.endDate]);

  useEffect(() => {
    const updatedMarketMoveRunMaster = {
      ...marketMoveRun?.marketMoveRun,
      ...marketMoveMetaData,
    };
    updateMarketMoveRunFields(updatedMarketMoveRunMaster);
  }, [marketMoveMetaData]);

  useEffect(() => {
    if (mmRunSaveRequest.counter > 0) {
      trigger().then((isValid) => {
        if (isValid) {
          handleSave(mmRunSaveRequest);
        } else {
          enqueueSnackbar(
            "Please correct the highlighted errors before saving.",
            {
              variant: "error",
            }
          );
        }
      });
    }
  }, [mmRunSaveRequest.counter]);

  // 5. Helper functions
  const handleSave = (request: MMRunSaveRequest) => {
    if (!marketMoveRun?.marketMoveRun) {
      return;
    }

    if (!marketMoveRun?.marketMoveRun?.marketMoveRunId) {
      const cleanedPayload = cleanMarketMoveRunPayload(marketMoveRun, "CREATE");
      cleanedPayload.marketMoveRun.marketMoveId =
        mmRunSaveRequest.marketMoveId || marketMoveMetaData.marketMoveId;

      cleanedPayload.marketMoveRunRecapDetails = cleanedPayload.marketMoveRunRecapDetails.map((detail) => ({
        ...detail,
        marketMoveId: Number(mmRunSaveRequest.marketMoveId || marketMoveMetaData.marketMoveId),
      }));

      createMarketMoveRun.mutate(cleanedPayload, {
        onSuccess: (mm) => {
          marketMoveRunReset(mm);
          setMarketMoveRunSubmitReady(true);
        },
        onError: (error: AxiosError) => {
          enqueueSnackbar(`Error creating market move run: ${error.message}`, {
            variant: "error",
          });
        },
      });
    } else {
      const cleanedPayload = cleanMarketMoveRunPayload(marketMoveRun, "UPDATE");
      cleanedPayload.marketMoveRun.runStatus = mmRunSaveRequest.runStatus;

      updateMarketMoveRun.mutate(cleanedPayload, {
        onSuccess: (mm) => {
          console.log("Market Move Run updated", mm);
          if (mmRunSaveRequest.runStatus === RunStatus.SIGNEDOFF) {
            enqueueSnackbar("Market Move Run submitted", {
              variant: "success",
            });
          } else {
            enqueueSnackbar("Market Move Run updated", { variant: "success" });
            setMarketMoveRunSubmitReady(true);
          }
          marketMoveRunReset(mm);
          setSaveOnRecap(false);
        },
        onError: (error: AxiosError) => {
          console.error("Error updating Market Move Run", error);
          enqueueSnackbar(`Error updating market move run: ${error.message}`, {
            variant: "error",
          });
        },
      });
    }
  };

  const cleanMarketMoveRunPayload = (
    payload: MarketMoveRunSchema,
    saveMode: "CREATE" | "UPDATE"
  ) => {
    if (!payload || !payload.marketMoveRun) {
      return payload;
    }

    const cleanedPayload = { ...payload };
    cleanedPayload.marketMoveRun.runStatus = RunStatus.DRAFT;

    if (saveMode === "CREATE") {
      cleanedPayload.marketMoveRunProductConfigs =
        cleanedPayload.marketMoveRunProductConfigs.map((config) => {
          const { mmRunProdConfigId, ...rest } = config;
          return rest;
        });

      cleanedPayload.marketMoveRunRecapDetails =
        cleanedPayload.marketMoveRunRecapDetails =
          cleanedPayload.marketMoveRunRecapDetails.map((detail) => {
            const { mmRunRecapId, ...rest } = detail;
            return rest;
          });
    }

    if (cleanedPayload.marketMoveRun.storeList) {
      delete cleanedPayload.marketMoveRun.storeList;
    }
    if (cleanedPayload.marketMoveRun.productConfigList) {
      delete cleanedPayload.marketMoveRun.productConfigList;
    }
    return cleanedPayload;
  };

  function mergeProductConfigs(
    existingConfigs: MarketMoveProductConfigSchema[],
    newConfigs: MarketMoveProductConfigSchema[]
  ): MarketMoveProductConfigSchema[] {
    const existingConfigMap = new Map<string, MarketMoveProductConfigSchema>();

    // Create a map of existing configs
    existingConfigs.forEach((config) => {
      existingConfigMap.set(config.productId, config);
    });

    // Merge new configs with existing ones, only including configs present in the new set
    return newConfigs.map((newConfig) => {
      const existingConfig = existingConfigMap.get(newConfig.productId);
      return {
        ...newConfig,
        mmRunProdConfigId:
          existingConfig?.mmRunProdConfigId ?? newConfig.mmRunProdConfigId,
        marketMoveRunId:
          existingConfig?.marketMoveRunId ?? newConfig.marketMoveRunId,
        productConfigId:
          existingConfig?.productConfigId ?? newConfig.productConfigId,
      };
    });
  }

  const updateMarketMoveRunFields = (
    marketMoveMetaData: MarketMoveMetaDataSchema | undefined
  ): void => {
    const mergedProductConfigs = mergeProductConfigs(
      data?.marketMoveRunProductConfigs || [], // Use data instead of marketMoveRun
      marketMoveMetaData?.productConfigList || []
    );
    const updatedProductConfigs = mergedProductConfigs.map((config) => ({
      ...config,
      marketMoveRunId: parseInt(marketMoveMetaData?.marketMoveRunId),
    }));
    const fieldsToUpdate: { name: keyof MarketMoveRunSchema; value: any }[] = [
      {
        name: "marketMoveRun",
        value: { ...marketMoveRun?.marketMoveRun, ...marketMoveMetaData },
      },
      { name: "marketMoveRunProductConfigs", value: updatedProductConfigs },
    ];

    fieldsToUpdate.forEach((field) => {
      marketMoveRunSetValue(field.name, field.value, { shouldDirty: true });
    });
  };

  return {
    isFetching,
    error,
  };
};