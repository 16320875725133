import { useEffect, useState } from "react";
import "react-resizable/css/styles.css";
import { useMarketMoveWindowSettings } from "../../hooks/useMarketMoveWindowSettings";
import { useRHFForms } from "../../hooks/useRHFForms";
import useGeneralStore, {ListViewSelectedStore} from "../../store/generalStore";
import useMarketMoveStore from "../../store/marketMoveStore";
import ActionCardContent from "./ActionCardContent";
import ActionCardHeader from "./ActionCardHeader";
import { StyledPaper } from "./ActionCardStyles";
import DraggableResizableWrapper from "./DraggableResizableWrapper";

interface ActionCardProps {
  listviewSelectedStores: ListViewSelectedStore[];
  extractedStoreCount: number;
}

const ActionCard = ({ customProps }) => {
  const [selectedProps, setSelectedProps] = useState<ActionCardProps>();
  const { setListViewSelectedStores, reset: resetGeneralStore } = useGeneralStore();
  const { reset: resetMarketMove } = useMarketMoveStore();
  useRHFForms()

  const {
    fullscreen,
    position,
    size,
    dragCursor,
    toggleFullScreen,
    handleOnDrag,
    handleOnStart,
    handleOnEnd,
    onResize,
    resetPosition,
  } = useMarketMoveWindowSettings();

  useEffect(() => {
    const subscription =
      customProps?.compRef?.mapLayerService?.openMarketMoveWindow$?.subscribe(
        (data: ActionCardProps) => {
          setSelectedProps(data);
          setListViewSelectedStores(data.listviewSelectedStores);
        }
      );
    return () => subscription.unsubscribe();
  }, []);

  return (
    <DraggableResizableWrapper
      fullscreen={fullscreen}
      position={position}
      size={size}
      onResize={onResize}
      onDrag={handleOnDrag}
      onStart={handleOnStart}
      onEnd={handleOnEnd}
    >
      <StyledPaper
        elevation={4}
        sx={{
          ...(fullscreen && {
            height: "calc(100vh - 120px) !important",
            width: "100vw !important",
          }),
          ...(!fullscreen && {
            height: `${size.height}px !important`,
            width: `${size.width}px !important`,
            maxHeight: "85vh !important",
            maxWidth: "99.8vw !important",
          }),
        }}
      >
        <ActionCardHeader
          fullscreen={fullscreen}
          dragCursor={dragCursor}
          toggleFullScreen={toggleFullScreen}
          handleClose={() => {
            resetMarketMove();
            resetGeneralStore();
            return customProps.compRefClose();
          }}
          resetPosition={resetPosition}
        />
        <ActionCardContent
          selectedProps={selectedProps}
          customProps={customProps}
        />
      </StyledPaper>
    </DraggableResizableWrapper>
  );
};

export default ActionCard;