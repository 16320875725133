import Box from "@mui/material/Box";
import Button, { type ButtonProps } from "@mui/material/Button";
import CircularProgress, {
  type CircularProgressProps,
} from "@mui/material/CircularProgress";
import IconButton, { type IconButtonProps } from "@mui/material/IconButton";

interface LoadingProps {
  loading: boolean;
  progressProps?: CircularProgressProps;
}

type IconProps = LoadingProps & {
  children: React.ReactNode;
};

export function LoadingIconButton(
  { loading, children, progressProps, ...iconButtonProps }: IconButtonProps & IconProps
): React.ReactElement {
  const iconSize = iconButtonProps.size ?? 'medium';
  // Calculate the progress size based on the icon size
  const progressSize = iconSize === 'large' ? 40 : iconSize === 'small' ? 24 : 32;

  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
        verticalAlign: "middle",
      }}
    >
      <IconButton
        {...iconButtonProps}
        disabled={loading}
        aria-busy={loading}
        aria-label={iconButtonProps['aria-label'] || (loading ? 'Loading' : undefined)}
      >
        {children}
      </IconButton>
      {loading && (
        <CircularProgress
          size={progressSize}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: `-${progressSize / 2}px`,
            marginLeft: `-${progressSize / 2}px`,
            zIndex: 1,
          }}
          {...progressProps}
        />
      )}
    </Box>
  );
}

type ButtonPropsWithLoading = LoadingProps & {
  children: React.ReactNode;
};

export function LoadingButton(
  { loading, children, progressProps, startIcon, endIcon, ...buttonProps }: ButtonProps & ButtonPropsWithLoading
): React.ReactElement {
  return (
    <Button
      {...buttonProps}
      disabled={loading || buttonProps.disabled}
      aria-busy={loading}
    >
      {loading && !startIcon && !endIcon ? (
        <CircularProgress size={progressProps?.size || 24} {...progressProps} />
      ) : (
        children
      )}
    </Button>
  );
}

LoadingIconButton.defaultProps = {
  progressProps: {},
};

LoadingButton.defaultProps = {
  progressProps: {},
};
