import constants from "../constants/constants.json";
import { formServiceUrl } from "../utils/apiUrlUtil";
import { createApiClient } from "./apiClient";

const {
    urlConstants: { compositeMaster },
  } = constants;

const { name: COMPOSITE_SERVICE_NAME, cancelOrEndMarketMove: cancelOrEndMarketMoveEndpoint} = compositeMaster;

const compositeBaseUrlFactory = async () => formServiceUrl(COMPOSITE_SERVICE_NAME);

const compositeApiClient = createApiClient(compositeBaseUrlFactory);

export interface MarketMoveCancelPayload {
    marketMoveRunId: number;
    marketMoveId: number;
    marketMoveName: string;
    currentRunStatus: string;
    startDate: string;
    endDate: string;
    requestTo: `CANCELMM` | 'CANCELSTORES';
    storeProductListToCancel?: {storeId: string}[];
    cancelDetails:{
        canceledBy: string;
        canceledDate: string;
        cancellationReason: string;
    };
}

export const cancelOrEndMarketMove = (payload: MarketMoveCancelPayload) => {
    return compositeApiClient.post<any>(cancelOrEndMarketMoveEndpoint, payload);
}
