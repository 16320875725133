export enum MarketMoveTemplateStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum RunStatus {
  DRAFT = "DRAFT",
  SIGNEDOFF = "SIGNEDOFF",
  INPROGRESS = "INPROGRESS",
  EXECUTED = "EXECUTED",
  CANCELLED = "CANCELED",
  UNKNOWN = "UNKNOWN",
}

export enum PriceMode {
  ABSOLUTE = "ABSOLUTE",
  INCREMENTAL = "INCREMENTAL",
}

export enum AfterMarketMoveAction {
  FORCEGENERATION = "FORCEGENERATION",
  RETURNTOPREVAILINGSTRATEGY = "RETURNTOPREVAILINGSTRATEGY",
}

