import React from "react";
import { Box, IconButton, Tooltip, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {
  FilterList as FilterListIcon,
  FilterListOff as FilterListOffIcon,
} from "@mui/icons-material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

interface MarketMoveActionsProps {
  handleAddClick: () => void;
  handleFilterClick: () => void;
  handleSortModeChange: () => void;
  handleSortOrderChange: () => void;
  showExpired: boolean;
  onlyPastExecutions: boolean;
  sortMode: "startDate" | "modifiedDate";
  sortOrder: "asc" | "desc";
}

const MarketMoveListActions: React.FC<MarketMoveActionsProps> = ({
  handleAddClick,
  handleFilterClick,
  handleSortModeChange,
  handleSortOrderChange,
  showExpired,
  onlyPastExecutions,
  sortMode,
  sortOrder,
}) => (
  <Box
    display="flex"
    justifyContent="flex-end"
    alignItems="center"
    sx={{
      borderBottom: 1,
      borderColor: "divider",
      padding: 1,
      marginBottom: 1,
    }}
  >
    <Tooltip
      title={`Sort by ${
        sortMode === "startDate" ? "Modified Date" : "Start Date"
      }`}
    >
      <Button
        variant="outlined"
        size="small"
        onClick={handleSortModeChange}
        sx={{ ml: 1 }}
      >
        {sortMode === "startDate" ? "Start Date" : "Modified Date"}
      </Button>
    </Tooltip>
    <Tooltip
      title={`Sort ${sortOrder === "desc" ? "ascending" : "descending"}`}
    >
      <IconButton
        aria-label="change sort order"
        onClick={handleSortOrderChange}
        sx={{ ml: 1 }}
      >
        {sortOrder === "desc" ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
      </IconButton>
    </Tooltip>
    {!onlyPastExecutions && (
      <Tooltip
        title={
          showExpired
            ? "Hide expired market moves"
            : "Show expired market moves"
        }
      >
        <IconButton
          color="secondary"
          aria-label="filter market moves"
          onClick={handleFilterClick}
        >
          {showExpired ? <FilterListIcon /> : <FilterListOffIcon />}
        </IconButton>
      </Tooltip>
    )}
    {!onlyPastExecutions && (
      <Tooltip title="Add market move">
        <IconButton aria-label="add market move" onClick={handleAddClick}>
          <AddIcon color="primary" />
        </IconButton>
      </Tooltip>
    )}
  </Box>
);

export default MarketMoveListActions;
