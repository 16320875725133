import Draggable from "react-draggable";
import { Resizable } from "react-resizable";
import { ReactNode } from "react";

const DraggableResizableWrapper = ({
  children,
  fullscreen,
  position,
  size,
  onResize,
  onDrag,
  onStart,
  onEnd,
}) => {
  const draggableProps = {
    handle: ".popup-header",
    bounds: { left: 0, top: 0, right: 1000, bottom: 720 },
    position,
    onDrag,
    onStart,
    onStop: onEnd,
    disabled: fullscreen,
    grid: [30, 30] as [number, number],
  };

  return (
    <Draggable {...draggableProps}>
      <Resizable
        width={fullscreen ? window.innerWidth : size.width}
        height={fullscreen ? window.innerHeight - 120 : size.height}
        onResize={onResize}
        resizeHandles={["se"]}
        handle={<div className="react-resizable-handle react-resizable-handle-se" />}
        disabled={fullscreen}
      >
        {children}
      </Resizable>
    </Draggable>
  );
};

export default DraggableResizableWrapper;