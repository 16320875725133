import { capitalize } from "@mui/material";
import { useMemo } from "react";
import { RunStatus } from "../interfaces/enums";
import {
  useMarketMoveMetaDataMethods,
  useMarketMoveRunMethods,
  usePreviewDetailsMethods,
} from "../store/formStore";
import useMarketMoveStore from "../store/marketMoveStore";

const useShouldSave = () => {
  const { formState: marketMoveMetaDataFormState } =
    useMarketMoveMetaDataMethods();
  const { formState: previewDetailsFormState } = usePreviewDetailsMethods();
  const { watch: marketMoveRunWatch } = useMarketMoveRunMethods();
  const disableEditing = useMarketMoveStore((state) => state.disableEditing);

  const marketMoveRunId = marketMoveRunWatch("marketMoveRun.marketMoveRunId");
  const marketMoveRunRecapDetails = marketMoveRunWatch(
    "marketMoveRunRecapDetails"
  );
  const marketMoveRunStatus = marketMoveRunWatch("marketMoveRun.runStatus");
  const isMetaDataDirty = marketMoveMetaDataFormState.isDirty;
  const isPreviewDetailsDirty = previewDetailsFormState.isDirty;
  const hasRecapDetailsWithoutId =
    !marketMoveRunId && marketMoveRunRecapDetails.length > 0;
  const editingEnabled = !disableEditing;
  const runInProgress = marketMoveRunStatus === RunStatus.INPROGRESS;
  const inProgressAndDirty = runInProgress && isMetaDataDirty;

  const shouldSave =
    (editingEnabled || inProgressAndDirty) &&
    ((isMetaDataDirty || isPreviewDetailsDirty || hasRecapDetailsWithoutId) &&
      marketMoveMetaDataFormState.isValid);

  const metaDataChangedFields = Object.keys(
    marketMoveMetaDataFormState.dirtyFields
  ).map(capitalize);
  const previewDetailsChangedFields = Object.keys(
    previewDetailsFormState.dirtyFields
  ).map(capitalize);

  const dirtyFieldsTooltip = useMemo(() => {
    const errors = marketMoveMetaDataFormState.errors;

    if (Object.keys(errors).length > 0) {
      const errorMessages = Object.entries(errors)
        .map(([field, error]) => `${field}: ${error?.message}`)
        .join(", ");
      return `Errors: ${errorMessages}`;
    }

    let changes = [];

    if (hasRecapDetailsWithoutId) {
      changes.push("Recap details available that haven't been saved");
    }

    if (isMetaDataDirty) {
      const metaDataChanges =
        metaDataChangedFields.length > 0
          ? `MetaData: ${metaDataChangedFields.join(", ")}`
          : null;

      const previewDetailsChanges =
        previewDetailsChangedFields.length > 0
          ? `PreviewDetails: ${previewDetailsChangedFields.join(", ")}`
          : null;

      changes.push(metaDataChanges, previewDetailsChanges);
    } else {
      changes.push("No changes to save");
    }

    return `Changes - ${changes.filter(Boolean).join(" | ")}`;
  }, [
    marketMoveMetaDataFormState.errors,
    hasRecapDetailsWithoutId,
    isMetaDataDirty,
    metaDataChangedFields,
    previewDetailsChangedFields,
  ]);

  const reason = useMemo(() => {
    let reasons = [];

    if (isMetaDataDirty) {
      reasons.push("Market Move MetaData has unsaved changes");
    }

    if (isPreviewDetailsDirty) {
      reasons.push("Preview Details have unsaved changes");
    }

    if (hasRecapDetailsWithoutId) {
      reasons.push("Recap details are available and have not been saved");
    }

    return reasons.join(" | ");
  }, [isMetaDataDirty, isPreviewDetailsDirty, hasRecapDetailsWithoutId]);

  return { shouldSave, reason, dirtyFieldsTooltip };
};

export default useShouldSave;
