import {
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  capitalize
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { useWatch } from "react-hook-form";
import { PriceMode, RunStatus } from "../../interfaces/enums";
import {
  MarketMoveMetaDataSchema,
  MarketMoveProductConfigSchema,
} from "../../schema/schemas";
import { useFormStore } from "../../store/formStore";
import useGeneralStore from "../../store/generalStore";
import useMarketMoveStore from "../../store/marketMoveStore";
import ProductSettingsTable from "./ProductSettingsTable";

const ProductSettingsContainer: React.FC = () => {
  const [selectedProduct, setSelectedProduct] = useState<string>("");
  const { uniqueProducts } = useGeneralStore();
  const { disableEditing } = useMarketMoveStore();
  const { marketMoveMetaDataMethods : {setValue, control}} = useFormStore()
  const addedProductsWatch = useWatch({ control, name: "productConfigList" });
  const currentMarketMove = useWatch<MarketMoveMetaDataSchema>({ control });

  const addedProducts = useMemo(
    () => addedProductsWatch || [],
    [addedProductsWatch]
  );


  const addProduct = (productId: string) => {
    if (!productId || currentMarketMove === null) return;
    const newProduct: MarketMoveProductConfigSchema = {
      productId: productId,
      excludePriceBelow: null,
      excludePriceAbove: null,
      priceMode: PriceMode.INCREMENTAL,
      priceIncrement: 10,
      absPrice: null,
      applyLinkedProducts: true,
      baseMinPrice: null,
    };
    setValue("productConfigList", [...addedProducts, newProduct], {
      shouldValidate: true,
      shouldDirty: true,
    });
    setSelectedProduct("");
  };

  const deleteProduct = (product: string) => {
    const updatedProducts = addedProducts.filter(
      (p) => p.productId !== product
    );
    setValue("productConfigList", updatedProducts, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const availableProducts = useMemo(() => {
    const filtered = uniqueProducts
      .filter(
        (product) => !addedProducts.some((p) => p.productId === product.id)
      )
      .sort((a, b) => a.id.localeCompare(b.id));
    return filtered;
  }, [uniqueProducts, addedProducts]);

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    setSelectedProduct(event.target.value);
  };

  const renderProductList = () => (
    <ProductSettingsTable deleteProduct={deleteProduct} />
  );

  const ProductSelect: React.FC = () => (
    
      <Select
        value={selectedProduct}
        onChange={(event) => {
          const selectedValue = event.target.value;
          handleSelectChange(event);
          addProduct(selectedValue);
        }}
        displayEmpty
        fullWidth
        disabled={currentMarketMove?.runStatus === RunStatus.INPROGRESS || disableEditing}
        size="small"
        sx={{ "& .MuiSelect-select": { textAlign: "center" } }}
      >
        <MenuItem value="" sx={{ justifyContent: "center" }}>
          Select a product
        </MenuItem>
        {availableProducts.map((product) => (
          <MenuItem key={product.id} value={product.id}>
            {product?.id} - {capitalize(product?.name)}
          </MenuItem>
        ))}
      </Select>
    
  );

  return (
    <Grid item xs={12}>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <ProductSelect />
        </Grid>
        <Grid item xs={9}>
          {renderProductList()}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProductSettingsContainer;
