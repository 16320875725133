import { SelectChangeEvent } from "@mui/material";
import {
  MRT_ColumnOrderState,
  MRT_ColumnSizingState,
  MRT_RowSelectionState,
} from "material-react-table";
import { useCallback, useState, useEffect } from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import { PriceMode } from "../interfaces/enums";
import {
  useFormActions,
  useMarketMoveMetaDataMethods,
} from "../store/formStore";

export const useTableConfig = () => {
  const [sortMode, setSortMode] = useState<PriceMode>(PriceMode.ABSOLUTE);
  const [columnSizing, setColumnSizing] =
    useLocalStorage<MRT_ColumnSizingState>("previewTable_columnSizing", {});
  const [rowSelection, setRowSelection] =
    useLocalStorage<MRT_RowSelectionState>("previewTable_rowSelection", {});
  const [columnOrder, setColumnOrder] = useLocalStorage<MRT_ColumnOrderState>(
    "previewTable_columnOrder",
    []
  );
  const [showLinkedProducts, setShowLinkedProducts] = useState(false);

  const {
    updateStoreProductConfig,
    getStoreProductConfig,
    clearStoreProductConfig,
  } = useFormActions();
  const { watch } = useMarketMoveMetaDataMethods();

  const storeList = watch("storeList");

  const storeProductConfigsManager = {
    updateConfig: updateStoreProductConfig,
    getConfig: getStoreProductConfig,
    storeList,
  };

  const handleSortModeChange = useCallback(
    (event: SelectChangeEvent<PriceMode>) => {
      setSortMode(event.target.value as PriceMode);
    },
    []
  );

  const updateColumnOrder = useCallback(
    (newOrder: MRT_ColumnOrderState) => {},
    [columnOrder, setColumnOrder]
  );

  // Initialize column order if it's empty
  useEffect(() => {
    if (columnOrder.length === 0) {
      updateColumnOrder([
        "action",
        "storeId",
        "displayName",
        "001",
        "019",
        "exclusionReason",
      ]);
    }
  }, [columnOrder, updateColumnOrder]);

  useEffect(() => {
    if (storeList) {
      storeList.forEach((store) => {
        if (!store.includeFlag) {
          clearStoreProductConfig(store.storeId);
        }
      });
    }
  }, [storeList, clearStoreProductConfig]);

  return {
    sortMode,
    handleSortModeChange,
    storeProductConfigsManager,
    showLinkedProducts,
    setShowLinkedProducts,
    tableState: {
      columnSizing,
      rowSelection,
      columnOrder,
      setRowSelection,
      setColumnOrder: updateColumnOrder,
      setColumnSizing,
    },
  };
};
