import React from "react";
import { Typography } from "@mui/material";

const ErrorMessage: React.FC<{ error: any }> = ({ error }) => (
  <Typography color="error">
    Error loading market moves: {error?.message ?? "Unknown error"}. Please try again later.
  </Typography>
);

export default ErrorMessage;
