import { capitalize } from "@mui/material";

const PreviewTableHeader: React.FC<{
  productName: string;
  IconComponent: any;
  iconColor: string;
}> = ({ productName, IconComponent, iconColor }) => (
  <span>
    {capitalize(productName)}{" "}
    <IconComponent sx={{ fontSize: 12, color: iconColor }} />
  </span>
);

export default PreviewTableHeader;
