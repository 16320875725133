import { Box, SelectChangeEvent } from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import React, { useEffect, useMemo, useState } from "react";
import { PriceMode, RunStatus } from "../../interfaces/enums";
import { MarketMoveRunDetailSchema } from "../../schema/schemas";
import { tableTheme } from "../../theme/theme";
import LinkedProductsToggle from "./LinkedProductsToggle";
import { generatePreviewTableColumnDefs } from "./previewTableColumnUtils";
import SortModeSelect from "./SortModeSelect";
import { useTableConfig } from "../../hooks/usePreviewTableConfig";
import { enqueueSnackbar } from "notistack";
import {
  generatePreviewTableData,
} from "./previewTableDataUtils";
import { useElasticData } from "../../hooks/elasticDataApiHooks";

const PastExecutionPreviewTable: React.FC<{
  editable?: boolean;
  mmRunData: MarketMoveRunDetailSchema[];
}> = React.memo(({ mmRunData }) => {
  const fetchElasticData = useElasticData();
  const [elasticData, setElasticData] = useState(null);

  useEffect(() => {
    fetchElasticData.mutate(null, {
      onSuccess: (data) => {
        console.debug("visops-market-move: elasticData ", data.length);
        setElasticData(data);
      },
      onError(error) {
        console.error("visops-market-move: elasticData error", error);
        setElasticData([]);
        enqueueSnackbar("Error fetching elastic data", { variant: "error" });
      },
    });
  }, []);

  const {
    sortMode,
    handleSortModeChange,
    storeProductConfigsManager,
    showLinkedProducts,
    setShowLinkedProducts,
    tableState,
  } = useTableConfig();

  const tableColumns = useMemo(
    () =>
      generatePreviewTableColumnDefs(
        mmRunData,
        sortMode,
        storeProductConfigsManager,
        showLinkedProducts,
        true,
        tableState.columnOrder
      ),
    [mmRunData, sortMode, showLinkedProducts, false, tableState.columnOrder]
  );

  const memoizedTableData = useMemo(() => {
    if(elasticData != null) {
      return generatePreviewTableData(elasticData, mmRunData);
    } else {
      return [];
    }
  }, [elasticData, mmRunData]);

  const getMissingDataMessage = () => {
    return "No data available for the selected stores and products.";
  };

  const renderTopToolbarCustomActions = () => (
    <Box sx={{ display: "flex", gap: "16px", flexWrap: "wrap" }}>
      <SortModeSelect
        sortMode={sortMode}
        handleSortModeChange={() =>
          handleSortModeChange({
            target: {
              value:
                sortMode === PriceMode.INCREMENTAL
                  ? PriceMode.ABSOLUTE
                  : PriceMode.INCREMENTAL,
            },
          } as SelectChangeEvent<PriceMode>)
        }
      />
      <LinkedProductsToggle
        showLinkedProducts={showLinkedProducts}
        setShowLinkedProducts={setShowLinkedProducts}
      />
    </Box>
  );

  const renderEmptyRowsFallback = () => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "16px",
        color: "text.secondary",
      }}
    >
      {getMissingDataMessage()}
    </Box>
  );

  const table = useMaterialReactTable({
    columns: tableColumns,
    data: memoizedTableData,
    enableGrouping: true,
    enableRowVirtualization: true,
    enablePagination: false,
    enableColumnDragging: true,
    enableColumnResizing: true,
    enableStickyHeader: true,
    enableEditing: false,
    enableRowSelection: false,
    positionToolbarAlertBanner: "none",
    muiTableHeadCellProps: { align: "center", sx: { whiteSpace: "nowrap" } },
    muiTableBodyCellProps: { align: "center" },
    getRowId: (originalRow) => originalRow.storeId,
    editDisplayMode: "cell",
    layoutMode: "grid-no-grow",
    enableCellActions: (cell) =>
      false,
    onColumnSizingChange: tableState.setColumnSizing,
    onColumnOrderChange: tableState.setColumnOrder,
    initialState: {
      expanded: true,
      density: "compact",
      sorting: [
        {
          id: "exclusionReason",
          desc: true,
        },
      ],
    },
    state: {
      columnSizing: tableState.columnSizing,
      columnOrder: tableState.columnOrder,
      isLoading: false,
    },
    defaultColumn: {
      size: 50,
      minSize: 10,
    },
    ...tableTheme,
    renderTopToolbarCustomActions,
    renderEmptyRowsFallback,
  });

  return <MaterialReactTable table={table} />;
});

export default PastExecutionPreviewTable;
