import { Autocomplete, Box, TextField } from "@mui/material";
import React from "react";
import { MarketMoveMetaDataSchema } from "../../../schema/schemas";

interface SearchProps {
  isSearchVisible: boolean;
  searchValue: string;
  handleSearchIconClick: () => void;
  handleSearchBlur: () => void;
  handleSearchFocus: () => void;
  setSearchValue: (value: string) => void;
  marketMoves: MarketMoveMetaDataSchema[];
}

const MarketMoveListSearch: React.FC<SearchProps> = ({
  isSearchVisible,
  searchValue,
  handleSearchBlur,
  handleSearchFocus,
  setSearchValue,
  marketMoves,
}) => {
  const renderAutocomplete = () => (
    <Box sx={{ padding: 1, borderBottom: 1, borderColor: "divider" }}>
      <Autocomplete
        freeSolo
        value={searchValue}
        onInputChange={(_event, newInputValue) => setSearchValue(newInputValue)}
        options={marketMoves?.map((move) => move.marketMoveName) || []}
        onBlur={handleSearchBlur}
        onFocus={handleSearchFocus}
        renderInput={renderTextField}
        fullWidth
      />
    </Box>
  );

  const renderTextField = (params: any) => (
    <TextField
      {...params}
      label="Search Market Moves"
      variant="outlined"
      size="small"
    />
  );

  return renderAutocomplete();
};

export default MarketMoveListSearch;
