import constants from "../constants/constants.json";
import {
  MarketMoveMetaDataSchema,
  MarketMoveProductConfigSchema,
  MarketMoveStoreListSchema,
} from "../schema/schemas";
import { formServiceUrl } from "../utils/apiUrlUtil";
import { createApiClient } from "./apiClient";

const {
  urlConstants: { metadataMaster },
} = constants;
const { name: SERVICE_NAME, marketMove: marketMoveEndpoints } = metadataMaster;

const baseUrlFactory = async () => formServiceUrl(SERVICE_NAME);

const apiClient = createApiClient(baseUrlFactory);

export interface MarketMoveSchemaSummaryDto {
  id: string;
  name: string;
  created_by: string;
  status: string;
}

export interface GetMMListParams {
  userId?: string;
  limit?: number;
  offset?: number;
  sortBy?: string;
  asc?: boolean;
}

interface IGetSingleMarketMove {
  master: MarketMoveMetaDataSchema;
  storeList: MarketMoveStoreListSchema[];
  productConfigList: MarketMoveProductConfigSchema[];
}

export const getMMList = (params?: GetMMListParams) => {
  const { userId, limit = 10, offset = 0, sortBy, asc } = params || {};
  const queryParams = new URLSearchParams();

  if (userId) queryParams.append("userId", userId);
  // queryParams.append("limit", limit.toString());
  queryParams.append("offset", offset.toString());
  if (sortBy) queryParams.append("sortBy", sortBy);
  if (asc !== undefined) queryParams.append("asc", asc.toString());

  const url = `${marketMoveEndpoints.getMarketMoves}?${queryParams}`;
  return apiClient.get<MarketMoveMetaDataSchema[]>(url);
};

export const getSingleMM = async (
  mmId: string
): Promise<MarketMoveMetaDataSchema> => {
  const response = await apiClient.get<IGetSingleMarketMove>(
    `${marketMoveEndpoints.getMM}?mm_id=${mmId}`
  );
  return {
    ...response.master,
    storeList: response.storeList,
    productConfigList: response.productConfigList,
  };
};

export const createMarketMove = (marketMove: MarketMoveMetaDataSchema) => {
  const processedMarketMove = {
    ...marketMove,
    storeList: marketMove.storeList?.map(({ flag, ...store }) => store) || [],
  };

  return apiClient.post<number>(
    marketMoveEndpoints.createMM,
    processedMarketMove
  );
};

export const updateMarketMove = (marketMove: MarketMoveMetaDataSchema) => {
  const { runStatus, ...marketMoveWithoutRunStatus } = marketMove;
  return apiClient.put<MarketMoveMetaDataSchema>(
    `${marketMoveEndpoints.updateMM}`,
    marketMoveWithoutRunStatus
  );
};
