import { useEffect, useState } from 'react';

export const usePermissions = () => {
  const [hasPermission, setHasPermission] = useState(false);

  useEffect(() => {
    const userGroups = JSON.parse(
      window.sessionStorage.getItem("userGroup") || "[]"
    );
    const { hostname } = window.location;

    const isLocalhost =
      hostname === "localhost";
    const isProd = hostname === "visops.7-eleven.com";
    const hasSuperAdminDevTestGroup = userGroups.includes("USER-SEIFS-VisOps-SuperAdmin-DEVQA-RW");
    const hasSuperAdminProdGroup = userGroups.includes(
      "USER-SEIFS-VisOps-SuperAdmin-PROD-RW"
    );

    setHasPermission(
      isLocalhost ||
      (!isProd && hasSuperAdminDevTestGroup) ||
      (isProd && hasSuperAdminProdGroup)
    );
  }, []);

  return { hasPermission };
};