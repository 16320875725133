import { Theme, ThemeOptions, TooltipProps, createTheme } from "@mui/material";

const defaultButtonSize = "medium" as "small" | "medium" | "large";

const baseTheme = createTheme({
  palette: {
    primary: {
      main: "#008001",
      contrastText: "#FFFFFF",
    },
    background: {
      default: "#faf9f7",
      paper: "#fff",
    },
    // text: {
    //   primary: "#333333",
    //   secondary: '#fff',
    // },
    secondary: {
      main: "#90EE90",
      contrastText: "#000",
    },
    success: {
      main: "hsla(165, 100%, 25%, 1)",
      contrastText: "#fff",
    },
    warning: {
      main: "hsla(21, 84%, 55%, 1)",
      contrastText: "#fff",
    },
    error: {
      main: "hsla(355, 78%, 53%, 1)",
      contrastText: "#fff",
    },
    divider: "#ddd",
  },
  typography: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: 14,
    h5: {
      fontSize: "1.5rem",
      fontWeight: 500,
    },
  },
  shape: {
    borderRadius: 8,
  },
});

const componentTheme = {
  components: {
    MuiTooltip: {
      defaultProps: {
        placement: "top" as TooltipProps["placement"],
        arrow: true,
        enterDelay: 1200,
        disableInteractive: false,
        enterNextDelay: 750,
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: "none",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 4,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        size: defaultButtonSize,
      },
      styleOverrides: {
        root: {
          textTransform: "none",
          height: "28px",
          variants: [],
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: "14px",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          // height: "32px",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          "&::before": {
            content: "''",
            width: "5px",
            // position: "absolute",
            top: 0,
            height: "100%",
            background: "green",
            left: 0,
            borderTopLeftRadius: "2px",
            borderBottomLeftRadius: "2px",
          },
          fontSize: "14px",
          fontWeight: "700",
          // width: "180px",
          // height: "32px",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-input": {
            padding: "8px 14px",
            height: "1.4em",
          },
        },
      },
    },
  },
};

export const tableTheme = {
  muiTableHeadCellProps: {
    sx: (theme: any) => ({
      align: "center" as "center" | "inherit" | "justify" | "left" | "right" | undefined,
    //   background: "#F0F9F1",
    //   borderRight: "1px solid #F0F9F1",
    //   color: "#333",
      fontSize: "11px",
      padding: '0.35rem',
      paddingTop: "0.6rem",
      paddingBottom: "0.4rem",
      maxHeight: "28px",
      "& .Mui-TableHeadCell-Content-Actions": {
        maxWidth: "20px",
        overflow: "hidden",
        transition: "max-width 0.3s ease, visibility 0.3s ease",
        position: "relative",
        "&::before": {
          content: '"\\2026"',
          position: "absolute",
          left: "5px",
          top: "50%",
          transform: "translateY(-50%)",
          fontSize: "16px",
          color: "#868a87",
        },
        "& > *": {
          visibility: "hidden",
        },
        "&:hover": {
          maxWidth: "200px",
          "&::before": {
            content: '""',
          },
          "& > *": {
            visibility: "visible",
          },
        },
      },
      "& .Mui-TableHeadCell-Content-Labels span.MuiBox-root": {
        display: "none",
        transition: "display 0.3s ease",
      },
      "& .Mui-TableHeadCell-Content-Labels span.MuiBadge-root": {
        display: "none",
        transition: "display 0.3s ease",
      },
      "& .Mui-TableHeadCell-Content-Labels:hover span.MuiBox-root": {
        display: "block",
      },
      "& .Mui-TableHeadCell-Content-Labels:hover span.MuiBadge-root": {
        display: "flex",
      },
    }),
  },
  muiTableBodyCellProps: ({
    cell,
    column,
    table,
  }: {
    cell: any;
    column: any;
    table: any;
  }) => ({
    align: "left" as "center" | "inherit" | "justify" | "left" | "right" | undefined,
    sx: (theme: any) => ({
      fontSize: "12px",
      maxHeight: "28px",
      paddingTop: "0rem",
      paddingBottom: "0rem",
      paddingLeft: "0.35rem",
      paddingRight: "0.35rem",
      '&.MuiTableCell-body': {
        // padding: '0rem',
        // paddingLeft: '0.75rem', // Slightly more left padding for body cells
      },
    }),
    onClick: () => {},
  }),
  muiTableBodyProps: {
    sx: (theme: any) => ({
      "& tr:nth-of-type(odd)": {
        backgroundColor: "#F3F2F0 !important",
      },
    }),
  },
};
export const themeDef: ThemeOptions = {
  ...baseTheme,
  ...componentTheme,
};