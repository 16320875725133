import { Box, Grid, Skeleton } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useElasticData } from "../../hooks/elasticDataApiHooks";
import { useMarketMove } from "../../hooks/useMarketMove";
import { useMarketMoveRun } from "../../hooks/useMarketMoveRun";
import useGeneralStore from "../../store/generalStore";
import MarketMoveForm from "../marketMove/MarketMoveForm";
import MarketMoveToggle from "../marketMove/marketMoveToggle/MarketMoveToggle";
import PreviewTable from "../previewTable/PreviewTable";

const EditMarketMoveView: React.FC = () => {
  const { setElasticData, showPreview: showSummary } = useGeneralStore();
  const { isFetching: isLoadingMarketMove } = useMarketMove();
  const { isFetching: isLoadingMarketMoveRun } = useMarketMoveRun();
  const fetchElasticData = useElasticData();

  const isLoading = isLoadingMarketMove || isLoadingMarketMoveRun;

  const LoadingSkeleton = () => (
    <Box sx={{ width: "100%", height: "100%", padding: 2 }}>
      <Skeleton variant="text" width="30%" height={40} sx={{ mb: 2 }} />
      <Skeleton variant="rectangular" width="100%" height={40} sx={{ mb: 2 }} />
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={6}>
          <Skeleton variant="rectangular" width="100%" height={40} />
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant="rectangular" width="100%" height={40} />
        </Grid>
      </Grid>
      <Skeleton variant="rectangular" width="100%" height={40} sx={{ mb: 2 }} />
      <Skeleton
        variant="rectangular"
        width="100%"
        height={200}
        sx={{ mb: 2 }}
      />
      <Skeleton variant="rectangular" width="20%" height={40} />
    </Box>
  );

  useEffect(() => {
    fetchElasticData.mutate(null, {
      onSuccess: (data) => {
        console.debug("visops-market-move: elasticData ", data.length);
        setElasticData(data);
      },
      onError(error) {
        console.error("visops-market-move: elasticData error", error);
        setElasticData([]);
        enqueueSnackbar("Error fetching elastic data", { variant: "error" });
      },
    });
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={2}>
        <MarketMoveToggle />
      </Grid>
      <Grid item xs={12} md={10}>
        <Grid container direction="column" style={{ height: "100%" }}>
          <Grid
            item
            style={{ height: "43vh", overflowY: "auto", position: "relative" }}
            border={1}
            padding={1}
            borderRadius={1}
          >
            <Box
              sx={{
                display: isLoading ? "block" : "none",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: 1,
                backgroundColor: "white",
              }}
            >
              <LoadingSkeleton />
            </Box>
            <Box sx={{ visibility: isLoading ? "hidden" : "visible" }}>
              <MarketMoveForm />
            </Box>
          </Grid>
          <Grid item border={1} borderRadius={2}>
            {showSummary && <PreviewTable editable={true} />}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EditMarketMoveView;
