import { Grid } from "@mui/material";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import { PriceMode } from "../../interfaces/enums";
import {
  MarketMoveRunDetailSchema
} from "../../schema/schemas";
import { formatValue } from "../../utils/priceFormatting";

const CustomTooltip = (props: TooltipProps) => (
  <Tooltip {...props} placement="top" arrow />
);

interface PreviewTablePriceCellProps {
  detail: MarketMoveRunDetailSchema;
  sortMode: PriceMode;
  isStale: boolean;
  stalePrice?: number; // New prop to indicate stale price
}

const ExcludedPrice = ({ reason }: { reason?: string }) => (
  <CustomTooltip title={reason || "Price excluded"}>
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      style={{
        backgroundColor: "rgb(243, 242, 240)",
        height: "100%",
        width: "100%",
      }}
    >
      Excluded
    </Grid>
  </CustomTooltip>
);

const getOffsetDisplay = (priceDifference: number) => {
  const color = priceDifference > 0 ? "green" : priceDifference < 0 ? "red" : "black";
  return <span style={{ color }}>{priceDifference.toFixed(2)}</span>;
};

const PreviewTablePriceCell = ({
  detail: value,
  sortMode,
  isStale,
  stalePrice,
}: PreviewTablePriceCellProps) => {
  if (
    !value ||
    value.excludePriceFlag ||
    !value.includeFlag
  ) {
    return <ExcludedPrice reason={value?.exclusionReason} />;
  }

  const { originalPrice, newPrice, delta } = value;

  if (!newPrice && !value?.manualPriceOverrideFlag) {
    return <span style={{ fontWeight: "normal" }}>N/A</span>;
  }

  const renderContent = () => {
    const isOverride = value?.manualPriceOverrideFlag;
    const mainValue = sortMode === PriceMode.INCREMENTAL
      ? getOffsetDisplay(delta || 0)
      : formatValue(isOverride ? value.manualPrice : (newPrice || 0), PriceMode.ABSOLUTE, false);

    const supValue = sortMode === PriceMode.INCREMENTAL
      ? formatValue(isOverride ? value.manualPrice : (newPrice || 0), PriceMode.ABSOLUTE, false)
      : getOffsetDisplay(delta || 0);

    return (
      <>
        <span style={{ 
          fontWeight: isOverride ? "bold" : "normal",
          position: "relative",
        }}>
          {mainValue}
          {isStale && (
            <span style={{
              position: "absolute",
              top: -5,
              right: 2,
              width: 8,
              height: 8,
              borderRadius: "50%",
              backgroundColor: "#FFA500",
              border: "1px solid #fff",
            }} />
          )}
        </span>
        <sup style={{ marginLeft: 4, fontWeight: isOverride ? "bold" : "normal" }}>{supValue}</sup>
      </>
    );
  };

  const tooltipTitle = () => {
    let title = `Original Price: ${originalPrice}`;
    if (isStale && stalePrice !== undefined) {
      title += ` | Pending override: ${formatValue(stalePrice, PriceMode.ABSOLUTE, false)}`;
    }
    return title;
  };

  return (
    <CustomTooltip title={tooltipTitle()}>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{ height: "100%", width: "100%" }}
      >
        <Grid item style={{ textAlign: "center" }}>
          {renderContent()}
        </Grid>
      </Grid>
    </CustomTooltip>
  );
};

export default PreviewTablePriceCell;