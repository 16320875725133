import { AxiosError } from "axios";
import { enqueueSnackbar } from "notistack";
import { useEffect, useMemo, useRef, useState } from "react";
import { useShallow } from "zustand/react/shallow";
import { generatePreviewTableData } from "../component/previewTable/previewTableDataUtils";
import { MarketMoveRunDetailSchema } from "../schema/schemas";
import { useFormActions, useFormStore } from "../store/formStore";
import useGeneralStore from "../store/generalStore";
import useMarketMoveStore from "../store/marketMoveStore";
import { deriveConfigFromRecap } from "../utils/marketMoveUtils";
import { useGetSingleMMRun } from "./marketMoveRunApiHooks";
import { useGetPreviewDetails } from "./previewDetailsApiHooks";

function transformData(
  data: MarketMoveRunDetailSchema[]
): MarketMoveRunDetailSchema[] {
  return data.map((item) => ({
    ...item,
    exclusionReason: item.exclusionReason ?? "",
  }));
}

function createDataMap(data: MarketMoveRunDetailSchema[]) {
  console.debug("[createDataMap] - Creating data map", data);
  const dataMap = new Map<string, MarketMoveRunDetailSchema>();
  data.forEach((item) => {
    const key = `${item.storeId}-${item.productId}`;
    dataMap.set(key, item);
  });
  return dataMap;
}

function mergeRecapData(
  existingMarketMoveRecapDetails: MarketMoveRunDetailSchema[],
  previewDetails: MarketMoveRunDetailSchema[]
) {
  const existingDataMap = createDataMap(existingMarketMoveRecapDetails);
  return previewDetails.map((newItem) => {
    const key = `${newItem.storeId}-${newItem.productId}`;
    const existingItem = existingDataMap.get(key);
    return {
      ...newItem,
      mmRunRecapId: existingItem?.mmRunRecapId ?? newItem.mmRunRecapId,
      marketMoveId: existingItem?.marketMoveId ?? newItem.marketMoveId,
    };
  });
}

export const usePreviewData = () => {
  const { elasticData, showRecap, showPastMarketMoves } = useGeneralStore();
  const { previewDetailsFetchReq, setPreviewStale, disableEditing } =
    useMarketMoveStore(
      useShallow((state) => ({
        previewDetailsFetchReq: state.previewDetailsFetchReq,
        setPreviewStale: state.setPreviewStale,
        disableEditing: state.disableEditing,
      }))
    );
  const [localRecapData, setLocalRecapData] = useState(null);

  const {
    previewDetailsMethods: {
      reset: resetPreviewDetails,
      setValue: setPreviewDetailsValue,
      watch: watchPreviewDetails,
    },
    marketMoveMetaDataMethods: { watch: watchMetaData },
    marketMoveRunMethods: {
      setValue: setMarketMoveRunValue,
      watch: watchMarketMoveRun,
    },
  } = useFormStore();
  const {
    getOriginalMarketMoveRunRecapDetails,
    setOriginalMarketMoveRunRecapDetails,
  } = useFormActions();
  const selectedMarketMoveStoreList = watchMetaData("storeList");
  const selectedMarketMoveProductConfigList =
    watchMetaData("productConfigList");
  const selectedMarketMoveId = watchMetaData("marketMoveId");
  const previewDetails = watchPreviewDetails();
  const marketMoveRunRecapDetails = watchMarketMoveRun(
    "marketMoveRunRecapDetails"
  );
  const marketMoveRunStatus = watchMarketMoveRun("marketMoveRun.runStatus");
  const marketMoveRunId = watchMarketMoveRun("marketMoveRun.marketMoveRunId");
  const marketMoveRunDetails = watchMarketMoveRun("marketMoveRunDetails");
  const { isFetching: isMMRunFetching } = useGetSingleMMRun(marketMoveRunId);

  // console.debug("usePreviewData - previewDetails", previewDetails);
  const {
    data: recapData,
    refetch: refetchRecapData,
    ...rest
  } = useGetPreviewDetails(previewDetails, {
    enabled: false,
    onSuccess: (data: MarketMoveRunDetailSchema[]) => {
      setPreviewStale(false);
      const originalRecapDetails = getOriginalMarketMoveRunRecapDetails();
      const existingMarketMoveRunData =
        originalRecapDetails || marketMoveRunRecapDetails || [];

      console.debug("usePreviewData - Using recap details for merge", {
        originalRecapDetails: originalRecapDetails?.length,
        marketMoveRunRecapDetails: marketMoveRunRecapDetails?.length,
        usingOriginal: !!originalRecapDetails,
      });
      const transformedData = transformData(data);
      console.debug("usePreviewData - transformedData", transformedData);

      // Get the list of enabled stores
      const enabledStores = selectedMarketMoveStoreList.filter(
        (store) => store.includeFlag
      );
      console.debug("usePreviewData - Enabled stores:", {
        count: enabledStores.length,
      });

      const mergedData = mergeRecapData(
        existingMarketMoveRunData,
        transformedData
      );
      console.debug("usePreviewData - mergedData", {
        mergedData,
        existingMarketMoveRunData,
      });

      // Update both the local state and the original recap details
      setLocalRecapData(mergedData);
      setMarketMoveRunValue("marketMoveRunRecapDetails", mergedData);

      // Update the original recap details with the merged data to ensure it stays in sync
      console.debug(
        "usePreviewData - Updating original recap details with merged data",
        { count: mergedData.length }
      );
      const derivedConfig = deriveConfigFromRecap({
        recapDetails: mergedData,
        selectedMarketMoveStoreList: selectedMarketMoveStoreList,
      });
      console.debug("usePreviewData - previewDetails", previewDetails);
      resetPreviewDetails({
        ...previewDetails,
        storeProductConfig: derivedConfig,
      });
      enqueueSnackbar("Preview data fetched successfully", {
        variant: "success",
      });
    },
    onError: (error: AxiosError) => {
      console.error("usePreviewData - Error fetching preview data", error);
      enqueueSnackbar(`Error fetching preview data: ${error.message}`, {
        variant: "error",
      });
    },
  });

  const [isReadyToFetch, setIsReadyToFetch] = useState(false);
  const isInitialMount = useRef(true);

  useEffect(() => {
    console.debug("usePreviewData - previewDetailsFetchReq effect triggered", {
      previewDetailsFetchReq,
    });
    if (previewDetailsFetchReq.shouldFetch) {
      console.debug("usePreviewData - Updating preview details", {
        storeList: selectedMarketMoveStoreList,
        productConfigs: selectedMarketMoveProductConfigList,
      });

      // Remove the filter and sync the complete store list
      setPreviewDetailsValue("marketMoveStores", selectedMarketMoveStoreList);
      setPreviewDetailsValue(
        "marketMoveProductConfigs",
        selectedMarketMoveProductConfigList
      );

      console.debug(
        "usePreviewData - Updated preview with complete store list",
        {
          completeStoreList: selectedMarketMoveStoreList,
          productConfigs: selectedMarketMoveProductConfigList,
        }
      );

      setIsReadyToFetch(true);
    }
  }, [previewDetailsFetchReq.counter]);

  useEffect(() => {
    if (!isInitialMount.current && isReadyToFetch) {
      console.debug("usePreviewData - Triggering refetch");
      refetchRecapData({
        queryKey: ["previewDetails", previewDetails],
      });
      setIsReadyToFetch(false);
    } else {
      isInitialMount.current = false;
    }
  }, [isReadyToFetch, previewDetails]);

  useEffect(() => {
    setLocalRecapData(null);
    setPreviewStale(true);
    console.debug(
      "usePreviewData - Reset local states. marketMoveId:",
      selectedMarketMoveId,
      "marketMoveRunId:",
      marketMoveRunId
    );
  }, [selectedMarketMoveId]);

  const recapDataToUse = useMemo(() => {
    console.debug(
      "usePreviewData - recapDataToUse",
      localRecapData,
      marketMoveRunRecapDetails
    );
    return localRecapData?.length > 0
      ? localRecapData
      : marketMoveRunRecapDetails;
  }, [localRecapData, marketMoveRunRecapDetails]);

  const memoizedTableData = useMemo(() => {
    console.debug("usePreviewData - memoizedTableData", recapDataToUse);

    const cancellationDetails =
      marketMoveRunDetails?.filter((detail) => detail.canceledDate !== null) ||
      [];
    console.debug(cancellationDetails);

    const mergedData = recapDataToUse ? [...recapDataToUse] : [];

    cancellationDetails.forEach((cancellation) => {
      const existingIndex = mergedData.findIndex(
        (item) =>
          item.productId === cancellation.productId &&
          item.storeId === cancellation.storeId
      );

      if (existingIndex >= 0) {
        mergedData[existingIndex] = {
          ...mergedData[existingIndex],
          canceledDate: cancellation.canceledDate,
          cancellationReason: cancellation.cancellationReason,
          canceledBy: cancellation.canceledBy,
        };
      } else {
        mergedData.push({
          ...cancellation,
          canceledDate: cancellation.canceledDate,
          cancellationReason: cancellation.cancellationReason,
          canceledBy: cancellation.canceledBy,
        });
      }
    });

    console.debug(
      "usePreviewData - Using merged data with cancellations",
      mergedData
    );
    return generatePreviewTableData(elasticData, mergedData);
  }, [elasticData, recapDataToUse, marketMoveRunDetails, marketMoveRunStatus]);

  return {
    recapData: recapDataToUse,
    tableData: memoizedTableData,
    callState: {
      shouldFetchPreview: previewDetailsFetchReq.shouldFetch,
      ...rest,
      isLoading: rest.isFetching || isMMRunFetching,
    },
  };
};
