/* eslint-disable no-console */
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  List,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ErrorMessage from "./ErrorMessage";
import PastExecutionMarketMoveListItem from "./PastExecutionMarketMoveListItem";
import { useInfiniteScrollGetMarketMoveRunsByRunStatus } from "../../../hooks/marketMoveRunApiHooks";
import PastExecutionMarketMoveListActions from "./PastExecutionMarketMoveListActions";

const MarketMoveToggle: React.FC<{
  onSelectedMarketMoveRunIdChanged: (id: string) => void;
}> = ({ onSelectedMarketMoveRunIdChanged }) => {
  console.debug("PastExecutionsToggle - Rendering");
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [searchTemp, setSearchTemp] = useState(null);
  const [searchValue, setSearchValue] = useState(null);
  const [sortMode, setSortMode] = useState<"startDate" | "modifiedDate">(
    "startDate"
  );
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");

  const [selectedMarketMoveRunId, setSelectedMarketMoveRunId] =
    useState<string>(null);

  useEffect(() => {
    onSelectedMarketMoveRunIdChanged(selectedMarketMoveRunId);
    console.log("RUNID CHANGED: ", selectedMarketMoveRunId);
  }, [selectedMarketMoveRunId]);

  const handleWarningModalClose = () => {
    setIsWarningModalOpen(false);
  };

  const {
    data: runData,
    isLoading: runDataIsLoading,
    isError: runDataIsError,
    error: runDataError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteScrollGetMarketMoveRunsByRunStatus(
    ["CANCELED", "EXECUTED"],
    sortMode,
    sortOrder,
    searchValue
  );

  const handleScroll = useCallback(
    (event: React.UIEvent<HTMLElement>) => {
      const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
      if (
        scrollHeight - scrollTop - clientHeight < 0.25 * scrollHeight &&
        hasNextPage &&
        !isFetchingNextPage
      ) {
        fetchNextPage();
      }
    },
    [fetchNextPage, hasNextPage, isFetchingNextPage]
  );

  const marketMoves = useMemo(
    () => runData?.pages.flatMap((page) => page.data) || [],
    [runData]
  );

  const handleSortModeChange = () => {
    setSortMode(sortMode === "startDate" ? "modifiedDate" : "startDate");
  };

  const handleSortOrderChange = () => {
    setSortOrder(sortOrder === "desc" ? "asc" : "desc");
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchValue(searchTemp);
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTemp]);

  // Render methods
  const renderWarningModal = () => {
    const style = {
      position: "absolute" as "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      bgcolor: "background.paper",
      border: "2px solid #000",
      boxShadow: 24,
      p: 4,
    };

    return (
      <Modal
        open={isWarningModalOpen}
        onClose={handleWarningModalClose}
        aria-labelledby="warning-modal-title"
        aria-describedby="warning-modal-description"
      >
        <Box sx={style}>
          <Typography id="warning-modal-title" variant="h6" component="h2">
            Warning
          </Typography>
          <Typography id="warning-modal-description" sx={{ mt: 2 }}>
            You have an unsaved market move draft. Please complete or discard it
            before adding a new one.
          </Typography>
          <Button onClick={handleWarningModalClose} sx={{ mt: 2 }}>
            Okay
          </Button>
        </Box>
      </Modal>
    );
  };

  if (runDataIsError) {
    return <ErrorMessage error={runDataError} />;
  }

  const renderTextField = (params: any) => (
    <TextField
      {...params}
      label="Search Market Moves"
      variant="outlined"
      size="small"
    />
  );

  return (
    <div>
      <PastExecutionMarketMoveListActions
        handleSortModeChange={handleSortModeChange}
        handleSortOrderChange={handleSortOrderChange}
        sortMode={sortMode}
        sortOrder={sortOrder}
      />
      <Box sx={{ padding: 1, borderBottom: 1, borderColor: "divider" }}>
        <Autocomplete
          freeSolo
          value={searchTemp}
          onInputChange={(_event, newInputValue) =>
            setSearchTemp(newInputValue)
          }
          options={marketMoves?.map((move) => move.marketMoveName) || []}
          renderInput={renderTextField}
          fullWidth
        />
      </Box>

      {runDataIsLoading ? (
        <CircularProgress />
      ) : (
        <>
          <Box
            sx={{
              height: "30vh",
              maxHeight: "30vh",
              overflowY: "auto",
              overflowX: "hidden",
            }}
            onScroll={handleScroll}
          >
            <List sx={{ width: "100%", padding: 0 }}>
              {runData?.pages?.map((group, i) =>
                group?.data?.map((value) => (
                  <PastExecutionMarketMoveListItem
                    key={i}
                    move={value}
                    selectedMarketMoveRunId={selectedMarketMoveRunId}
                    handleMarketMoveChange={(id) => {
                      setSelectedMarketMoveRunId(id);
                    }}
                  />
                ))
              )}
              {!hasNextPage && (
                <Typography variant="body2" sx={{ textAlign: "center", py: 2 }}>
                  No more market moves to load.
                </Typography>
              )}
            </List>
          </Box>
        </>
      )}
    </div>
  );
};

export default MarketMoveToggle;
