import { Box, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledPaper = styled(Paper)(({ theme }) => ({
  position: "fixed",
  borderRadius: theme.shape.borderRadius,
  fontSize: "0.875rem",
  minWidth: "10%",
  minHeight: "10%",
  transition: "transform 0.3s ease",
  cursor: "default",
  resize: "none",
  display: "flex",
  flexDirection: "column",
  boxShadow: theme.shadows[4],
  backgroundColor: theme.palette.background.paper,
}));

export const Content = styled(Box)({
  height: "100%",
  overflow: "auto",
  padding: "0 8px",
  cursor: "text",
  display: "flex",
  flexDirection: "row",
  justifyContent: "left",
  alignContent: "flex-start",
  flexWrap: "wrap",
});

export const Footer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  fontSize: "0.9rem",
  borderTop: `0.5px solid ${theme.palette.divider}`,
  height: 20,
}));