import { AxiosError } from "axios";
import { enqueueSnackbar } from "notistack";
import { useEffect, useRef } from "react";
import { useShallow } from 'zustand/react/shallow';
import { RunStatus } from "../interfaces/enums";
import {
  MarketMoveMetaDataSchema,
  MarketMoveProductConfigSchema,
  MarketMoveStoreListSchema,
  UpdateFlag
} from "../schema/schemas";
import {
  useMarketMoveMetaDataMethods,
  useMarketMoveRunMethods,
} from "../store/formStore";
import useGeneralStore from "../store/generalStore";
import useMarketMoveStore from "../store/marketMoveStore";
import { generateDefaultMarketMove } from "../utils/marketMoveUtils";
import { getUserName } from "../utils/misc";
import { formatISODate } from "../utils/textUtils";
import {
  useCreateMarketMove,
  useGetSingleMM,
  useUpdateMarketMove,
} from "./marketMoveMetaDataApiHooks";

export const useMarketMove = () => {
  // 1. Set up state and hooks
  const {
    selectedMarketMoveId,
    incrementMMRunSaveRequest,
    setDisableEditing,
    setMarketMoveRunSubmitReady,
    mmMetaDataSaveReq,
    setSelectedMarketMoveId
  } = useMarketMoveStore(
    useShallow(state => ({
      selectedMarketMoveId: state.selectedMarketMoveId,
      incrementMMRunSaveRequest: state.incrementMMRunSaveRequest,
      setDisableEditing: state.setDisableEditing,
      setMarketMoveRunSubmitReady: state.setMarketMoveRunSubmitReady,
      mmMetaDataSaveReq: state.mmMetaDataSaveReq,
      setSelectedMarketMoveId: state.setSelectedMarketMoveId
    }))
  );
  
  const showPastMarketMoves = useGeneralStore(state => state.showPastMarketMoves);
  const marketMoveMetaDataMethods = useMarketMoveMetaDataMethods();
  const marketMoveRunMethods = useMarketMoveRunMethods();
  const {
    reset: marketMoveMetaDataReset,
    getValues: marketMoveMetaDataGetValues,
    formState: marketMoveMetaDataFormState,
  } = marketMoveMetaDataMethods;
  const { watch: marketMoveRunWatch } = marketMoveRunMethods;
  const initialMarketMoveRef = useRef<MarketMoveMetaDataSchema | null>(null);

  const marketMoveRunRecapDetails = marketMoveRunWatch('marketMoveRunRecapDetails');

  // 3. Fetch data
  const { data, error, isFetching } = useGetSingleMM(selectedMarketMoveId);

  // 4. Handle data updates
  useEffect(() => {
    if (data && selectedMarketMoveId) {
      if (data.runStatus === RunStatus.UNKNOWN) {
        data.runStatus = RunStatus.DRAFT;
      }
      if (data.marketMoveRunId) {
        data.marketMoveRunId = data.marketMoveRunId.toString();
      }
      if (!showPastMarketMoves && (data.runStatus === RunStatus.CANCELLED || data.runStatus === RunStatus.EXECUTED)) {
        data.marketMoveRunId = undefined;
        const {startDate: newStartDate, endDate: newEndDate} = generateDefaultMarketMove()
        data.startDate = newStartDate;
        data.endDate = newEndDate;
      }
      if (data.runStatus == RunStatus.INPROGRESS) {
        setDisableEditing(true);
      } else {
        setDisableEditing(false);
      }
      initialMarketMoveRef.current = data;
      marketMoveMetaDataReset(data);
    }
  }, [data]);

  const createMarketMoveMetaData = useCreateMarketMove();
  const updateMarketMoveMetaData = useUpdateMarketMove();

  useEffect(() => {
    if (mmMetaDataSaveReq.counter > 0) {
      marketMoveMetaDataMethods.trigger().then((isValid) => {
        if (isValid) {
          const marketMoveMetaData = marketMoveMetaDataGetValues();
          if (marketMoveMetaData.marketMoveId == "") {
            handleCreateMarketMove(marketMoveMetaData);
          } else {
            handleUpdateMarketMove(marketMoveMetaData);
          }
        } else {
          enqueueSnackbar("Form is not valid", {
            variant: "error",
          });
        }
      });
    }
  }, [mmMetaDataSaveReq.counter]);

  const handleCreateMarketMove = (mm: MarketMoveMetaDataSchema) => {
    const newMM = { ...mm };
    delete newMM.marketMoveId;
    createMarketMoveMetaData.mutate(newMM, {
      onSuccess: (mmId: number) => {
        setSelectedMarketMoveId(mmId.toString());
        if (!mmMetaDataSaveReq.triggerMMRunSave) {
          setMarketMoveRunSubmitReady(true);
        }
        if (marketMoveRunRecapDetails?.length > 0 && mmMetaDataSaveReq.triggerMMRunSave) {
          incrementMMRunSaveRequest(RunStatus.DRAFT, mmId.toString());
        }
      },
    });
  };

  const handleUpdateMarketMove = (mm: MarketMoveMetaDataSchema) => {
    const updatePayload = prepareUpdatePayload(mm);
    updateMarketMoveMetaData.mutate(updatePayload as MarketMoveMetaDataSchema, {
      onSuccess: () => {
        console.log("Market Move updated successfully");
        if (!mmMetaDataSaveReq.triggerMMRunSave) {
          setMarketMoveRunSubmitReady(true);
        }
        if (marketMoveRunRecapDetails?.length > 0 && mmMetaDataSaveReq.triggerMMRunSave) {
          incrementMMRunSaveRequest(mmMetaDataSaveReq.runStatus);
        }
      },
      onError: (error: AxiosError) => {
        console.error("Failed to update Market Move", error);
        enqueueSnackbar(`Error updating market move: ${error.message}`, {
          variant: "error",
        });
      },
    });
  };

  // Add these helper functions from the original MarketMoveSettings component

  const prepareUpdatePayload = (
    mm: MarketMoveMetaDataSchema
  ): Partial<MarketMoveMetaDataSchema> => {
    const initialMarketMove = initialMarketMoveRef.current;

    const updatePayload: Partial<MarketMoveMetaDataSchema> = {
      marketMoveId: String(mm.marketMoveId),
      marketMoveName: marketMoveMetaDataFormState.dirtyFields.marketMoveName
        ? mm.marketMoveName
        : initialMarketMove?.marketMoveName,
      marketMoveDescription: marketMoveMetaDataFormState.dirtyFields
        .marketMoveDescription
        ? mm.marketMoveDescription
        : initialMarketMove?.marketMoveDescription,
      startDate: marketMoveMetaDataFormState.dirtyFields.startDate
        ? (mm.startDate)
        : initialMarketMove?.startDate,
      endDate: marketMoveMetaDataFormState.dirtyFields.endDate
        ? mm.endDate
        : initialMarketMove?.endDate,
      templateStatus: initialMarketMove?.templateStatus ?? mm.templateStatus,
      postMMAction: marketMoveMetaDataFormState.dirtyFields.postMMAction
        ? mm.postMMAction
        : initialMarketMove?.postMMAction,
      createdDate: initialMarketMove?.createdDate ?? mm.createdDate,
      createdBy: initialMarketMove?.createdBy ?? mm.createdBy,
      modifiedDate: formatISODate(),
      modifiedBy: getUserName(),
      productConfigList:
        mm.productConfigList ?? initialMarketMove?.productConfigList ?? [],
    };

    if (marketMoveMetaDataFormState.dirtyFields.productConfigList) {
      updatePayload.productConfigList = prepareProductConfigList(
        mm,
        initialMarketMove
      );
    }

    updatePayload.storeList = prepareStoreList(mm, initialMarketMove);

    return updatePayload;
  };

  // 5. Helper functions
  const prepareProductConfigList = (
    mm: MarketMoveMetaDataSchema,
    initialMarketMove: MarketMoveMetaDataSchema | null
  ) => {
    const currentProductIds =
      mm.productConfigList?.map((config) => config.productId) || [];
    const removedProducts =
      initialMarketMove?.productConfigList
        ?.filter(
          (initialConfig) =>
            !currentProductIds.includes(initialConfig.productId)
        )
        .map((initialConfig) => ({
          ...initialConfig,
          marketMoveId: Number(mm.marketMoveId),
          flag: "REMOVE",
        })) || [];

    const updatedProducts =
      mm.productConfigList?.map((config) => {
        const initialConfig = initialMarketMove?.productConfigList?.find(
          (c) => c.productId === config.productId
        );
        const isNewProduct = !config.productConfigId;
        return {
          ...config,
          marketMoveId: Number(mm.marketMoveId),
          productConfigId: initialConfig?.productConfigId,
          flag: isNewProduct
            ? "INSERT"
            : hasProductConfigChanged(config, initialConfig)
            ? "UPDATE"
            : "NOUPDATE",
        };
      }) || [];

    return [...updatedProducts, ...removedProducts];
  };

  const prepareStoreList = (
    mm: MarketMoveMetaDataSchema,
    initialMarketMove: MarketMoveMetaDataSchema | null
  ) => {
    return mm.storeList?.map((store) => {
      const initialStore = initialMarketMove?.storeList?.find(
        (s) => s.storeId === store.storeId
      );
      const flag = initialStore
        ? determineStoreFlag(store, initialStore)
        : "INSERT";

      return {
        ...store,
        marketMoveId: Number(mm.marketMoveId),
        flag: flag,
      };
    });
  };

  const hasProductConfigChanged = (
    config: MarketMoveProductConfigSchema,
    initialConfig: MarketMoveProductConfigSchema
  ) => {
    const { productConfigId: _, ...configWithoutId } = config;
    const { productConfigId: __, ...initialConfigWithoutId } = initialConfig;
    return (
      JSON.stringify(configWithoutId) !== JSON.stringify(initialConfigWithoutId)
    );
  };

  const determineStoreFlag = (
    stores: MarketMoveStoreListSchema,
    initialStore: MarketMoveStoreListSchema | undefined
  ): UpdateFlag => {
    if (!initialStore) return "INSERT";
    if (hasStoreChanged(stores, initialStore)) return "UPDATE";
    if (stores.includeFlag === false) return "REMOVE";
    return "NOUPDATE";
  };

  const hasStoreChanged = (
    stores: MarketMoveStoreListSchema,
    initialStore: MarketMoveStoreListSchema
  ): boolean => {
    return JSON.stringify(stores) !== JSON.stringify(initialStore);
  };

  // 6. Return result
  return { isFetching, error };
};