import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import ActionCard from "./component/ActionCard/ActionCard";
const storeIds = [
  "38156",
  "41928",
  "32795",
  "38218",
  "32517",
  "35981",
  "32530",
];
const subscribeMock = (callback) => {
  const mockActionCardProps = {
    listviewSelectedStores: storeIds,
    extractedStoreCount: storeIds.length,
  };

  setTimeout(() => {
    callback(mockActionCardProps);
  }, 1000);

  return {
    unsubscribe: () => {},
  };
};

const mockCustomProps = {
  compRef: {
    mapLayerService: {
      openMarketMoveWindow$: {
        subscribe: subscribeMock,
      },
    },
  },
  compRefClose: () => console.debug("Mock close function called"),
};

const isLocalhost = window.location.hostname.includes("localhost");

if (window.location.href.includes("https://visops.7-eleven.com")) {
  window.console.debug = function () {};
}

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  // @ts-ignore
  rootComponent: isLocalhost
    ? (props) => <ActionCard customProps={mockCustomProps} {...props} />
    : ActionCard,
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    return null;
  },
});

export const { bootstrap, mount, unmount, update } = lifecycles;
