import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Grid,
  Typography,
} from '@mui/material';
import { formatLocalDate } from '../../../utils/textUtils';

interface SubmitConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  startDate?: string;
  endDate?: string;
}

const SubmitConfirmationDialog: React.FC<SubmitConfirmationDialogProps> = ({
  open,
  onClose,
  onSubmit,
  startDate,
  endDate,
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle align="center" fontWeight="bold">Confirm Market Move Submission</DialogTitle>
      <DialogContent>
        <Grid container direction="column" alignItems="center" spacing={2}>
          
          <Grid item>
          <DialogContentText variant="body1" fontWeight="bold" align="center">
              {`Start Date`} 
              {" -> "}
              {`End Date`}
            </DialogContentText>
            <DialogContentText variant="body1" align="center">
              {startDate && `${formatLocalDate(startDate)}`} 
              {" -> "}
              {endDate && `${formatLocalDate(endDate)}`}
            </DialogContentText>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item>
          <Button onClick={onSubmit} color="primary" variant="contained">
              Submit
            </Button>
          </Grid>
          <Grid item>
          <Button onClick={onClose} color="error" variant="contained">
              Cancel
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default SubmitConfirmationDialog;