import {
  Button,
  Dialog,
  DialogActions,
  DialogContent
} from "@mui/material";
import useDialogStore from "../../store/useDialogStore";

const GlobalDialog = () => {
  const {
    isOpen,
    content,
    actions,
    closeDialog,
    acceptCallback,
    discardCallback,
    acceptButtonText = "Accept",
    discardButtonText = "Discard",
  } = useDialogStore();

  return (
    <Dialog open={isOpen} onClose={closeDialog}>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        {actions || (
          <>
            <Button
              onClick={acceptCallback}
              color="primary"
              sx={{ color: "green" }}
            >
              {acceptButtonText}
            </Button>
            <Button
              onClick={discardCallback}
              color="secondary"
              sx={{ color: "red" }}
            >
              {discardButtonText}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default GlobalDialog;
