import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useFormActions, useFormStore } from "../store/formStore";
import {
  MarketMoveMetaDataSchema,
  marketMoveMetaDataSchema,
  marketMoveRunSchema,
  MarketMoveRunSchema,
  PreviewDetailsSchema,
  previewDetailsSchema,
} from "../schema/schemas";
import {
  generateDefaultMarketMove,
  generateDefaultMarketMoveRun,
  generateDefaultPreviewDetails,
} from "../utils/marketMoveUtils";

export const useRHFForms = () => {
  const {
    setMarketMoveMetaDataMethods,
    setPreviewDetailsMethods,
    setMarketMoveRunMethods,
  } = useFormActions();

  const metaDataMethods = useForm<MarketMoveMetaDataSchema>({
    resolver: zodResolver(marketMoveMetaDataSchema),
    defaultValues: generateDefaultMarketMove(),
    mode: "all",
  });

  const previewDetailsMethods = useForm<PreviewDetailsSchema>({
    resolver: zodResolver(previewDetailsSchema),
    defaultValues: generateDefaultPreviewDetails(),
    mode: "all",
  });

  const marketMoveRunMethods = useForm<MarketMoveRunSchema>({
    resolver: zodResolver(marketMoveRunSchema),
    defaultValues: generateDefaultMarketMoveRun(),
    mode: "all",
  });

  useEffect(() => {
    setMarketMoveMetaDataMethods(metaDataMethods);
    setPreviewDetailsMethods(previewDetailsMethods);
    setMarketMoveRunMethods(marketMoveRunMethods);
  }, [metaDataMethods, previewDetailsMethods, marketMoveRunMethods]);
};
