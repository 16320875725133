import React from "react";
import { Box, IconButton, Tooltip, Button } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

interface MarketMoveActionsProps {
  handleSortModeChange: () => void;
  handleSortOrderChange: () => void;
  sortMode: "startDate" | "modifiedDate";
  sortOrder: "asc" | "desc";
}

const PastExecutionMarketMoveListActions: React.FC<MarketMoveActionsProps> = ({
  handleSortModeChange,
  handleSortOrderChange,
  sortMode,
  sortOrder,
}) => (
  <Box
    display="flex"
    justifyContent="flex-end"
    alignItems="center"
    sx={{
      borderBottom: 1,
      borderColor: "divider",
      padding: 1,
      marginBottom: 1,
    }}
  >
    <Tooltip
      title={`Sort by ${
        sortMode === "startDate" ? "Modified Date" : "Start Date"
      }`}
    >
      <Button
        variant="outlined"
        size="small"
        onClick={handleSortModeChange}
        sx={{ ml: 1 }}
      >
        {sortMode === "startDate" ? "Start Date" : "Modified Date"}
      </Button>
    </Tooltip>
    <Tooltip
      title={`Sort ${sortOrder === "desc" ? "ascending" : "descending"}`}
    >
      <IconButton
        aria-label="change sort order"
        onClick={handleSortOrderChange}
        sx={{ ml: 1 }}
      >
        {sortOrder === "desc" ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
      </IconButton>
    </Tooltip>
  </Box>
);

export default PastExecutionMarketMoveListActions;
