import { SelectChangeEvent } from "@mui/material";
import {
  MRT_ColumnOrderState,
  MRT_ColumnSizingState,
  MRT_RowSelectionState,
} from "material-react-table";
import { useCallback, useEffect, useState } from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import { PriceMode, RunStatus } from "../interfaces/enums";
import {
  useFormActions,
  useMarketMoveMetaDataMethods,
  useMarketMoveRunMethods,
} from "../store/formStore";
import useGeneralStore from "../store/generalStore";

export const useTableConfig = () => {
  const [sortMode, setSortMode] = useState<PriceMode>(PriceMode.ABSOLUTE);
  const [columnSizing, setColumnSizing] =
    useLocalStorage<MRT_ColumnSizingState>("previewTable_columnSizing", {});
  const [rowSelection, setRowSelection] =
    useLocalStorage<MRT_RowSelectionState>("previewTable_rowSelection", {});
  const [columnOrder, setColumnOrder] = useLocalStorage<MRT_ColumnOrderState>(
    "previewTable_columnOrder",
    []
  );
  const [showLinkedProducts, setShowLinkedProducts] = useState(false);
  const showPastMarketMoves = useGeneralStore(state => state.showPastMarketMoves);

  const {
    updateStoreProductConfig,
    getStoreProductConfig,
    clearStoreProductConfig,
  } = useFormActions();
  const { watch, setValue } = useMarketMoveMetaDataMethods();
  const { watch: watchMMRunDetails } = useMarketMoveRunMethods();

  const storeList = watch("storeList");
  const runStatus = watch("runStatus")
  const recapDetails = watchMMRunDetails("marketMoveRunRecapDetails")

  function handleIncludeToggle(storeId: string, currentInclude: boolean) {
    const newIncludeFlag = !currentInclude;
  
    recapDetails?.filter(detail => detail.storeId === storeId).forEach(detail => {
      updateStoreProductConfig({
        storeId: detail.storeId,
        productId: detail.productId,
        updates: {
          includeFlag: newIncludeFlag,
        },
      })
    })
  }

  const previewTableContext = {
    updateConfig: updateStoreProductConfig,
    getConfig: getStoreProductConfig,
    storeList,
    setValue,
    handleIncludeToggle,
    showPastMarketMoves,
  };

  const handleSortModeChange = useCallback(
    (event: SelectChangeEvent<PriceMode>) => {
      setSortMode(event.target.value as PriceMode);
    },
    []
  );

  const updateColumnOrder = useCallback(
    (newOrder: MRT_ColumnOrderState) => {},
    [columnOrder, setColumnOrder]
  );

  // Initialize column order if it's empty
  useEffect(() => {
    if (columnOrder.length === 0) {
      updateColumnOrder([
        "action",
        "storeId",
        "displayName",
        "001",
        "019",
        "exclusionReason",
      ]);
    }
  }, [columnOrder, updateColumnOrder]);

  useEffect(() => {
    if (storeList) {
      storeList.forEach((store) => {
        if (!store.includeFlag) {
          clearStoreProductConfig(store.storeId);
        }
      });
    }
  }, [storeList, clearStoreProductConfig]);

  return {
    sortMode,
    handleSortModeChange,
    storeProductConfigsManager: previewTableContext,
    showLinkedProducts,
    setShowLinkedProducts,
    tableState: {
      columnSizing,
      rowSelection,
      columnOrder,
      setRowSelection,
      setColumnOrder: updateColumnOrder,
      setColumnSizing,
    },
  };
};
