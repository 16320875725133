import { MRT_ColumnDef } from "material-react-table";

export const listViewColumnDef: MRT_ColumnDef<any>[] = [
  { header: "Store ID", accessorKey: "importcode", maxSize: 50 },
  // { header: "SEI ID", accessorKey: "seiid" },
  {
    header: "Description",
    accessorKey: "displayname",
  },
  {
    header: "DMA",
    accessorKey: "dma",
    filterVariant: "multi-select",
  },
  {
    header: "City",
    accessorKey: "city",
    filterVariant: "multi-select",
  },
  {
    header: "Brand",
    accessorKey: "brandname",
    filterVariant: "multi-select",
  },
  // {
  //   header: "POS Type",
  //   accessorKey: "postype",
  //   filterVariant: "multi-select",
  // },
];

