import AddIcon from "@mui/icons-material/Add";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Box, Button, Grid, Tooltip } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { RunStatus } from "../../interfaces/enums";
import {
  useFormActions,
  useMarketMoveMetaDataMethods,
  useMarketMoveRunMethods,
  usePreviewDetailsMethods,
} from "../../store/formStore";
import useGeneralStore from "../../store/generalStore";
import useMarketMoveStore from "../../store/marketMoveStore";
import { areConfigsIdentical, areStoreListsIdentical, generateDefaultMarketMove } from "../../utils/marketMoveUtils";
import { formatErrorsForTooltip } from "../../utils/textUtils";
import ProductSettingsContainer from "../product/ProductSettingsContainer";
import MarketMoveSettings from "./marketMoveSettings/MarketMoveSettings";
import useShouldSave from '../../hooks/useShouldSave';
import { EndDateUpdateBox } from "../shared/EndDateUpdate";
import useDialogStore from "../../store/useDialogStore";
import { useShallow } from "zustand/react/shallow";



const MarketMoveForm: React.FC = () => {
  const { showPreview, toggleRecap, setShowPreview, listViewSelectedStores } =
    useGeneralStore();
  const {
    setSelectedMarketMoveId,
    setPreviewStale,
    isPreviewStale,
    incrementMMMetaDataSaveReq,
    disableEditing,
    hasInitialLoadCompleted,
    setHasInitialLoadCompleted,
    incrementPreviewDetailsFetchReq,
    setSaveOnRecap,
    saveOnRecap,
  } = useMarketMoveStore();
  const {
    reset,
    watch: watchMarketMoveMetaData,
    formState: { isValid: isMetaDataValid, errors: marketMoveMetaDataErrors, isDirty: isMetaDataDirty },
  } = useMarketMoveMetaDataMethods();

  const {
    watch: watchPreviewDetails,
    formState: {
      dirtyFields: previewDetailsDirtyFields = {},
    },
  } = usePreviewDetailsMethods();

  const { openGlobalDialog, closeGlobalDialog } = useDialogStore(
    useShallow((state) => ({
      openGlobalDialog: state.openDialog,
      closeGlobalDialog: state.closeDialog,
    }))
  );

  const { watch: watchMarketMoveRun, formState: { defaultValues: marketMoveRunDefaultValues } } = useMarketMoveRunMethods();
  const { setRunStatus } = useFormActions();
  const marketMoveStoreList = watchMarketMoveMetaData('storeList');
  const marketMoveProductConfigList = watchMarketMoveMetaData('productConfigList');
  const marketMoveId = watchMarketMoveMetaData('marketMoveId');

  const marketMoveRunRecapDetails = watchMarketMoveRun('marketMoveRunRecapDetails');
  const marketMoveRunStatus = watchMarketMoveRun('marketMoveRun.runStatus');
  const marketMoveRunEndDate = watchMarketMoveRun('marketMoveRun.endDate');

  const previewMarketMoveProductConfigs = watchPreviewDetails('marketMoveProductConfigs');
  const previewMarketMoveStores = watchPreviewDetails('marketMoveStores');

  const { shouldSave } = useShouldSave();

  const previewButtonState = useMemo(() => {
    const guards = [
      {
        name: "No stores in storeList",
        value: !marketMoveStoreList?.length,
      },
      { name: "Editing Disabled", value: disableEditing },
    ];

    const triggers = [
      {
        name: "No preview details have been generated - refresh needed",
        value: !marketMoveRunRecapDetails?.length,
      },
      { name: "The preview is currently hidden", value: !showPreview },
      {
        name: `The product configurations have changed`,
        value: !areConfigsIdentical(
          marketMoveProductConfigList,
          previewMarketMoveProductConfigs
        ),
      },
      {
        name: `The store list has changed - refresh needed`,
        value: !areStoreListsIdentical(
          marketMoveStoreList,
          previewMarketMoveStores
        ),
      },
      {
        name: "The store product configurations have changed - refresh needed",
        value: previewDetailsDirtyFields.storeProductConfig,
      },
    ];

    const allGuardsPassed = guards.every((guard) => !guard.value);
    const anyTriggerActive = triggers.some((trigger) => trigger.value);

    const isDisabled = !allGuardsPassed || !anyTriggerActive;
    const tooltipText = [...guards, ...triggers]
      .filter((condition) => condition.value)
      .map((condition) => condition.name)
      .join(" | ");


    return { isDisabled, tooltipText };
  }, [
    marketMoveStoreList,
    marketMoveProductConfigList,
    previewMarketMoveProductConfigs,
    previewMarketMoveStores,
    previewDetailsDirtyFields,
    marketMoveRunRecapDetails,
    showPreview,
    disableEditing,
  ]);

  const recapButtonState = useMemo(() => {
    const conditions = [
      {
        name: `Market Move Settings errors:\n${formatErrorsForTooltip(
          marketMoveMetaDataErrors
        )}`,
        value: !isMetaDataValid,
      },
      {
        name: "Product configurations are needed before proceeding to recap",
        value: !marketMoveProductConfigList?.length,
      },
      {
        name: "Preview details are needed before proceeding to recap - please refresh the preview",
        value: !marketMoveRunRecapDetails?.length,
      },
      {
        name: "All recap products are excluded - can not proceed to recap",
        value: marketMoveRunRecapDetails
          ? !marketMoveRunRecapDetails.filter(
              (recap) => recap.includeFlag
            ).length
          : false,
      },
      {
        name: "No included stores in storeList - can not proceed to recap",
        value: !marketMoveStoreList?.filter(
          (store) => store.includeFlag
        ).length,
      },
      { name: "Preview needs a refresh - please refresh the preview", value: !previewButtonState.isDisabled },
    ];

    const isDisabled =
      conditions.some((condition) => condition.value) && !disableEditing;
    const tooltipText = conditions
      .filter((condition) => condition.value)
      .map((condition) => condition.name)
      .join(" | ");

    return { isDisabled, tooltipText };
  }, [
    marketMoveRunRecapDetails,
    previewButtonState.isDisabled,
    isMetaDataValid,
    disableEditing,
    marketMoveMetaDataErrors,
  ]);

  useEffect(() => {
    setPreviewStale(previewButtonState.isDisabled);
  }, [previewButtonState.isDisabled]);


  const handlePreviewClick = () => {
    incrementPreviewDetailsFetchReq(true);
    setShowPreview(true);
    setRunStatus(RunStatus.DRAFT);
    setSaveOnRecap(true);
  };

  const getButtonText = () =>
    isPreviewStale || showPreview ? "Refresh Preview" : "Preview";

  const getRecapButtonText = () => {
    if ((disableEditing || marketMoveRunStatus === RunStatus.INPROGRESS) && !shouldSave) 
      return "View Details";
    if (!saveOnRecap && marketMoveRunStatus === RunStatus.SIGNEDOFF && !isMetaDataDirty) 
      return "Recap";
    return "Recap & Submit";
  };

  const onRecapClicked = () => {
    const editingEnabled = !disableEditing;
    const runInProgress = marketMoveRunStatus === RunStatus.INPROGRESS;
    const shouldSaveRun = saveOnRecap || isMetaDataDirty;
    const status = () => {
      if (runInProgress) return RunStatus.INPROGRESS;
      if (shouldSaveRun) return RunStatus.DRAFT;
      return undefined;
    }
    if (editingEnabled || (runInProgress && shouldSave)) {
      if (runInProgress) {
        openGlobalDialog({
          content: <EndDateUpdateBox originalEndDate={marketMoveRunDefaultValues.marketMoveRun?.endDate} newEndDate={marketMoveRunEndDate} />,
          acceptCallback: () => {
            toggleRecap();
            incrementMMMetaDataSaveReq(status(), shouldSaveRun);
            closeGlobalDialog();
          },
          discardCallback: closeGlobalDialog,
          acceptButtonText: "Yes, Save",
          discardButtonText: "No, Cancel",
          actions: null,
        });
      } else {
        toggleRecap();
        return incrementMMMetaDataSaveReq(status(), shouldSaveRun);
      }
    } else {
      toggleRecap();
    }
  };

  const handleAddNew = () => {
    const mm = generateDefaultMarketMove();
    mm.marketMoveId = "";
    reset(mm);
    setSelectedMarketMoveId("");
  };



  useEffect(() => {
    if (
      !hasInitialLoadCompleted &&
      !disableEditing &&
      listViewSelectedStores.length
    ) {
      handleAddNew();
      setHasInitialLoadCompleted(true);
    }
  }, [hasInitialLoadCompleted, disableEditing, listViewSelectedStores]);

  if (marketMoveId === null) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexGrow={1}
        minHeight="100%"
      >
        <Button
          variant="outlined"
          color="primary"
          startIcon={<AddIcon />}
          size="large"
          onClick={handleAddNew}
        >
          Add New Market Move
        </Button>
      </Box>
    );
  }

  return (
    <form onSubmit={() => {}}>
      <Grid container spacing={0.5} border={0} borderRadius={2}>
        <MarketMoveSettings />
        <ProductSettingsContainer />
        <Grid item xs={12} sx={{ mt: 1 }}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Tooltip title={previewButtonState.tooltipText}>
                <span>
                  <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    fullWidth
                    disabled={previewButtonState.isDisabled}
                    onClick={handlePreviewClick}
                    size="small"
                  >
                    {getButtonText()}
                  </Button>
                </span>
              </Tooltip>
            </Grid>
            <Grid item xs={9}>
              <Tooltip
                title={
                  recapButtonState.isDisabled
                    ? recapButtonState.tooltipText
                    : ""
                }
              >
                <span>
                  <Button
                    variant="contained"
                    color="success"
                    size="small"
                    onClick={onRecapClicked}
                    fullWidth
                    disabled={recapButtonState.isDisabled}
                    startIcon={
                      recapButtonState.isDisabled ? <ErrorOutlineIcon /> : null
                    }
                  >
                    {getRecapButtonText()}
                  </Button>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default MarketMoveForm;
