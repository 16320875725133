import {
  DoneAll as DoneAllIcon,
  EditNote as EditNoteIcon,
  ScheduleSend as ScheduleSendIcon,
  Send as SendIcon,
  CancelScheduleSend as CancelScheduleSendIcon,
} from "@mui/icons-material";
import {
  Box,
  Collapse,
  List,
  ListItemButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { RunStatus } from "../../../interfaces/enums";
import { MarketMoveMetaDataSchema } from "../../../schema/schemas";
import TruncatedMarketMoveName from "./TruncatedMarketMoveName";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  MarketMoveMetaDataWithJoinedRunsSchema,
  MarketMoveRunSchema,
} from "../../../schema/pastExecutionSchemas";

interface PastExecutionMarketMoveListItemProps {
  move: MarketMoveMetaDataWithJoinedRunsSchema;
  selectedMarketMoveRunId: string | null;
  handleMarketMoveChange: (newMarketMoveId: string | null) => void;
}

const formatDate = (dateString: string | undefined): string => {
  if (!dateString) return "";
  const date = new Date(dateString);
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

const getMarketMoveStyle = (
  move: MarketMoveMetaDataWithJoinedRunsSchema
): React.CSSProperties => {
  const today = new Date();
  const startDate = new Date(move.startDate ?? "");
  const endDate = new Date(move.endDate ?? "");

  if (today >= startDate && today <= endDate) {
    return { fontWeight: "bold", backgroundColor: "" };
  }
  return today < startDate ? { backgroundColor: "" } : { backgroundColor: "" };
};

const getStatusIcon = (
  move: MarketMoveMetaDataWithJoinedRunsSchema | undefined | null
): { icon: React.ReactElement; tooltip: string } => {
  switch (move?.runStatus) {
    case RunStatus.SIGNEDOFF:
      return {
        icon: <ScheduleSendIcon color="primary" fontSize="small" />,
        tooltip: "Signed Off",
      };
    case RunStatus.INPROGRESS:
      return {
        icon: <SendIcon color="secondary" fontSize="small" />,
        tooltip: "In Progress",
      };
    case RunStatus.EXECUTED:
      return {
        icon: <DoneAllIcon color="success" fontSize="small" />,
        tooltip: "Executed",
      };
    case RunStatus.CANCELLED:
      return {
        icon: <CancelScheduleSendIcon color="error" fontSize="small" />,
        tooltip: "Cancelled",
      };
    case RunStatus.DRAFT:
    default:
      return {
        icon: <EditNoteIcon color="action" fontSize="small" />,
        tooltip: "Draft",
      };
  }
};

const getMarketMoveHoverInfo = (move: MarketMoveMetaDataSchema): string => {
  return `ID: ${move.marketMoveId || "N/A"}
Modified: ${move.modifiedDate || "N/A"}
Created: ${move.createdDate || "N/A"}`;
};

const getMarketMoveRunHoverInfo = (move: MarketMoveRunSchema): string => {
  if (move.runStatus == RunStatus.CANCELLED) {
    return `ID: ${move.marketMoveId || "N/A"}
    Run ID: ${move.marketMoveRunId || "N/A"}
    Canceled By: ${move.canceledBy || "N/A"}
    Canceled Date: ${move.canceledDate || "N/A"}
    Reason: ${move.cancellationReason || "N/A"}`;
  }

  return `ID: ${move.marketMoveId || "N/A"}
  Run ID: ${move.marketMoveRunId || "N/A"}
  Recap Date: ${move.recapDate || "N/A"}`;
};

const PastExecutionMarketMoveListItem: React.FC<
  PastExecutionMarketMoveListItemProps
> = ({ move, selectedMarketMoveRunId, handleMarketMoveChange }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <Tooltip title={getMarketMoveHoverInfo(move)} placement="right" arrow>
        <ListItemButton
          onClick={toggleExpanded}
          sx={{
            ...getMarketMoveStyle(move),
            "&.Mui-selected": {
              ...getMarketMoveStyle(move),
              border: "2px solid green",
            },
            "&:hover": {
              opacity: 0.8,
              ...getMarketMoveStyle(move),
            },
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Tooltip
            title={getStatusIcon(move?.marketMoveRuns[0]).tooltip}
            placement="top"
            arrow
          >
            <Box>{getStatusIcon(move?.marketMoveRuns[0]).icon}</Box>
          </Tooltip>
          <Box sx={{ flexGrow: 1, ml: 2, mr: 2 }}>
            <TruncatedMarketMoveName name={move.marketMoveName} />
            <Stack
              direction="row"
              spacing={0.5}
              alignItems="center"
              sx={{ mt: 0.5 }}
            >
              {move.createdBy && (
                <>
                  <Typography variant="caption" color="textSecondary">
                    {move.createdBy}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    •
                  </Typography>
                </>
              )}
              <Typography variant="caption" color="textSecondary">
                {move.marketMoveRuns.length || 0} executions
              </Typography>
              <Typography variant="caption" color="textSecondary">
                •
              </Typography>
              <Typography variant="caption" color="textSecondary">
                Stores: {move.storeCount || 0}
              </Typography>
            </Stack>
          </Box>
          {isExpanded ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </Tooltip>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <List>
          {move.marketMoveRuns.map((run) => (
            <Tooltip
              key={run.marketMoveRunId}
              title={getMarketMoveRunHoverInfo(run)}
              placement="right"
              arrow
            >
              <ListItemButton
                selected={selectedMarketMoveRunId === run.marketMoveRunId}
                onClick={(event) => handleMarketMoveChange(run.marketMoveRunId)}
                sx={{ pl: 4 }}
              >
                <Tooltip
                  title={getStatusIcon(run).tooltip}
                  placement="top"
                  arrow
                >
                  <Box>{getStatusIcon(run).icon}</Box>
                </Tooltip>
                <Box sx={{ flexGrow: 1, ml: 2, mr: 2 }}>
                  <Typography variant="body1" color="textPrimary">
                    {formatDate(run.startDate)} - {formatDate(run.endDate)}
                  </Typography>
                  <Stack
                    direction="row"
                    spacing={0.5}
                    alignItems="center"
                    sx={{ mt: 0.5 }}
                  >
                    <TruncatedMarketMoveName name={move.marketMoveName} />
                  </Stack>
                </Box>
              </ListItemButton>
            </Tooltip>
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default PastExecutionMarketMoveListItem;
