import Box from "@mui/material/Box";

import { Typography } from "@mui/material";

function highlightDateChanges(startDate: string, endDate: string): string {
  const startParts = startDate.split(/[- :]/);
  const endParts = endDate.split(/[- :]/);

  const highlightedParts = startParts.map((part, index) => {
    return part === endParts[index] ? part : `<b>${endParts[index]}</b>`;
  });

  // Join the date parts with dashes and time parts with colons
  return `${highlightedParts.slice(0, 3).join('-')} ${highlightedParts.slice(3).join(':')}`;
}

export function EndDateUpdateBox({ originalEndDate, newEndDate } : Readonly<{originalEndDate: string, newEndDate: string}>) {
    const highlightedEndDate = highlightDateChanges(originalEndDate, newEndDate);

    return (
      <Box sx={{ padding: 2, textAlign: "center" }}>
        <Typography variant="h6" gutterBottom>
          Update End Date
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          You are about to update the <b>end date</b> of a market move that is currently running.
          <Typography>Are you sure you want to proceed?</Typography>
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            marginBottom: 2,
          }}
        >
          <Box>
            <Typography variant="subtitle1" sx={{ color: "#6c757d" }}>
              Current End Date:
            </Typography>
            <Typography variant="body2" sx={{ color: "primary" }}>
              {originalEndDate}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" sx={{ color: "#6c757d" }}>
              New End Date:
            </Typography>
            <Typography variant="body2" sx={{ color: "primary" }} dangerouslySetInnerHTML={{ __html: highlightedEndDate }} />
          </Box>
        </Box>
        <Typography variant="caption" sx={{ color: "#dc3545" }}>
          Please ensure the new date is correct before proceeding.
        </Typography>
      </Box>
    );
  }
