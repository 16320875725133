import { UseFormReturn } from "react-hook-form";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { RunStatus } from "../interfaces/enums";
import {
  MarketMoveMetaDataSchema,
  MarketMoveRunSchema,
  PreviewDetailsSchema,
  StoreProductConfigSchema,
} from "../schema/schemas";

const baseStoreProductConfig: Omit<
  StoreProductConfigSchema,
  "storeId" | "productId"
> = {
  includeFlag: true,
  manualPriceOverrideFlag: false,
  manualPrice: 0,
};

interface FormState {
  marketMoveMetaDataMethods: UseFormReturn<MarketMoveMetaDataSchema> | null;
  previewDetailsMethods: UseFormReturn<PreviewDetailsSchema> | null;
  marketMoveRunMethods: UseFormReturn<MarketMoveRunSchema> | null;
  actions: {
    setMarketMoveMetaDataMethods: (
      methods: UseFormReturn<MarketMoveMetaDataSchema>
    ) => void;
    setPreviewDetailsMethods: (
      methods: UseFormReturn<PreviewDetailsSchema>
    ) => void;
    setMarketMoveRunMethods: (
      methods: UseFormReturn<MarketMoveRunSchema>
    ) => void;
    updateStoreProductConfig: (
      storeId: string,
      productId: string,
      updates: Partial<StoreProductConfigSchema>
    ) => void;
    getStoreProductConfig: (
      storeId: string,
      productId: string
    ) => StoreProductConfigSchema | undefined;
    setRunStatus: (status: RunStatus) => void;
    clearStoreProductConfig: (storeId: string, productId?: string) => void;
  };
}

export const useFormStore = create<FormState>()(
  devtools(
    (set, get) => ({
      marketMoveMetaDataMethods: null,
      previewDetailsMethods: null,
      marketMoveRunMethods: null,
      actions: {
        setMarketMoveMetaDataMethods: (methods) =>
          set({ marketMoveMetaDataMethods: methods }),
        setPreviewDetailsMethods: (methods) =>
          set({ previewDetailsMethods: methods }),
        setMarketMoveRunMethods: (methods) =>
          set({ marketMoveRunMethods: methods }),
        updateStoreProductConfig: (
          storeId: string,
          productId: string,
          updates: Partial<StoreProductConfigSchema>
        ) => {
          const { previewDetailsMethods, marketMoveMetaDataMethods: {getValues: mmGetValues, setValue: mmSetValue} } = get();
          if (!previewDetailsMethods) return;

          const selectedMarketMove = mmGetValues();

          if (
            !selectedMarketMove?.storeList?.filter(
              (s) => s.storeId === storeId
            )[0]?.includeFlag
          ) {
            mmSetValue("storeList", selectedMarketMove?.storeList.map(s => {
              if (s.storeId === storeId) {
                return { ...s, includeFlag: true };
              }
              return s;
            }), {
              shouldValidate: true,
              shouldDirty: true,
            });
            return;
          }
          const currentConfig =
            previewDetailsMethods.getValues().storeProductConfig;
          const existingConfig = get().actions.getStoreProductConfig(
            storeId,
            productId
          );

          let updatedConfig: StoreProductConfigSchema[];

          if (existingConfig) {
            // Update existing configuration
            updatedConfig = currentConfig.map(config => {
              if (config.storeId === storeId && config.productId === productId) {
                return { ...config, ...updates };
              }
              return config;
            });
          } else {
            // Add new configuration
            const newConfig = {
              storeId,
              productId,
              ...baseStoreProductConfig,
              ...updates,
            };
            updatedConfig = [...currentConfig, newConfig];
          }

          console.debug("Updated store product config", updatedConfig);

          // Update the form state with the new configuration
          previewDetailsMethods.setValue("storeProductConfig", updatedConfig, {
            shouldValidate: true,
            shouldDirty: true,
          });
        },
        getStoreProductConfig: (storeId, productId) => {
          const { previewDetailsMethods } = get();
          if (!previewDetailsMethods) return undefined;

          const storeProductConfig =
            previewDetailsMethods.getValues().storeProductConfig;
          return storeProductConfig.find(
            (config) =>
              config.storeId === storeId && config.productId === productId
          );
        },
        setRunStatus: (status: RunStatus) => {
          const { marketMoveRunMethods } = get();
          if (marketMoveRunMethods) {
            marketMoveRunMethods.setValue("marketMoveRun.runStatus", status, {
              shouldValidate: true,
              shouldDirty: true,
            });
          }
        },
        clearStoreProductConfig: (storeId, productId) => {
          const { previewDetailsMethods } = get();
          if (!previewDetailsMethods) return;

          const currentConfig = previewDetailsMethods.getValues().storeProductConfig;

          // Filter out configurations based on the presence of productId
          const updatedConfig = currentConfig.filter((config) => {
            if (productId) {
              // Remove specific product for the store
              return !(config.storeId === storeId && config.productId === productId);
            } else {
              // Remove all products for the store
              return config.storeId !== storeId;
            }
          });

          // Update the form state with the new configuration
          previewDetailsMethods.setValue("storeProductConfig", updatedConfig, {
            shouldValidate: true,
            shouldDirty: true,
          });

          console.debug(`Cleared store product config for storeId: ${storeId}${productId ? `, productId: ${productId}` : ' (all products)'}`);
        },
      },
    }),
    { name: "FormStore" }
  )
);

export const useMarketMoveMetaDataMethods = () =>
  useFormStore((state) => state.marketMoveMetaDataMethods);
export const usePreviewDetailsMethods = () => useFormStore((state) => state.previewDetailsMethods);

// Override isDirty for previewData

export const useMarketMoveRunMethods = () =>
  useFormStore((state) => state.marketMoveRunMethods);
export const useFormActions = () => useFormStore((state) => state.actions);

