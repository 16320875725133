import constants from "../constants/constants.json";
import {
  MarketMoveRunDetailSchema,
  PreviewDetailsSchema
} from "../schema/schemas";
import { formServiceUrl } from "../utils/apiUrlUtil";
import { createApiClient } from "./apiClient";

const {
  urlConstants: { compositeMaster },
} = constants;
const { name: SERVICE_NAME, generatePreviewDetails } = compositeMaster;
const baseUrlFactory = async () => formServiceUrl(SERVICE_NAME, false);

const apiClient = createApiClient(baseUrlFactory);

export const fetchPreviewDetails = async (
  previewDetails: PreviewDetailsSchema
) => {
  const url = `${generatePreviewDetails}`;
  return await apiClient.post<MarketMoveRunDetailSchema[]>(url, {
    ...previewDetails,
  });
};
