import { CancelToken } from "axios";
import constants from "../constants/constants.json";
import { formServiceUrl } from "../utils/apiUrlUtil";
import { createApiClient } from "./apiClient";

const {
  urlConstants: { compositeMaster },
} = constants;

const {
  name: SERVICE_NAME,
  getStoresFromES
} = compositeMaster;

const baseUrlFactory = async () => formServiceUrl(SERVICE_NAME);

const apiClient = createApiClient(baseUrlFactory);

interface ElasticSearchResponse {
  hits: {
    hits: Array<{
      _source: any;
      _id: string;
    }>;
  };
}

const fetchFromElastic = async (size:number): Promise<ElasticSearchResponse> => {
  return await apiClient.get<ElasticSearchResponse>(`${getStoresFromES}?size=${size}`)
};

export const fetchStoresFromElastic = (size:number) =>
  fetchFromElastic(size).then((result) =>
    result.hits.hits.map((hit) => hit._source)
  );

export const fetchStoresWithSearchAfter = (
  searchAfterIndex: string,
  batchSize: number,
) =>
  fetchFromElastic(batchSize);

export const fetchAllStores = async () => {
  const batchSize = 8000;
  const response = await fetchStoresWithSearchAfter("", batchSize);
  return response.hits.hits.map((hit) => hit._source);
};
