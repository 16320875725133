import { enqueueSnackbar } from 'notistack';
import { useSnackbar } from 'notistack';
import { useFormStore } from '../store/formStore';
import { getUserName } from '../utils/misc';
import { formatISODate } from '../utils/textUtils';
import { cancelOrEndMarketMove, MarketMoveCancelPayload } from '../api/marketMoveCancelApi';
import axios from 'axios';
import { useQueryClient } from '@tanstack/react-query';

interface UseMarketMoveCancelOptions {
    onSuccess?: (response: any) => void;
    onError?: (error: any) => void;
}

export const useMarketMoveCancel = (options: UseMarketMoveCancelOptions = {}) => {
    const { enqueueSnackbar } = useSnackbar();
    const { marketMoveMetaDataMethods, marketMoveRunMethods } = useFormStore();
    const queryClient = useQueryClient()

    const cancelMarketMove = async(
        requestTo: 'CANCELMM' | 'CANCELSTORES',
        selectedStoreIds?: string[]
    ) => {
        const currentMarketMove = marketMoveMetaDataMethods.getValues();
        const currentMarketMoveRun = marketMoveRunMethods.getValues();

        const payload: MarketMoveCancelPayload = {
            marketMoveRunId: Number(currentMarketMoveRun?.marketMoveRun.marketMoveRunId),
            marketMoveId: Number(currentMarketMove?.marketMoveId),
            marketMoveName: currentMarketMove?.marketMoveName,
            currentRunStatus: currentMarketMoveRun?.marketMoveRun.runStatus,
            startDate: currentMarketMove?.startDate,
            endDate: currentMarketMove?.endDate,
            requestTo,
            cancelDetails: {
                canceledBy: getUserName(),
                canceledDate: formatISODate(new Date()),
                cancellationReason: requestTo === 'CANCELMM' ? "Whole Market Move Canceled" : "Stores Removed from Market Move By user"
            }
        };

        if(requestTo === 'CANCELSTORES' && selectedStoreIds){
            payload.storeProductListToCancel = selectedStoreIds.map(storeId => ({storeId}))
        }

        try {
            console.log(`Payload for ${requestTo}:`, payload);
            const response = await cancelOrEndMarketMove(payload);
            
            enqueueSnackbar(
                requestTo === 'CANCELMM' 
                ? 'Market Move Canceled Successfully'
                : 'Selected Store Successfully Removed From Market Move',
                {variant: 'success'}
            );

            queryClient.invalidateQueries({ queryKey: ['marketMoves'] });
            queryClient.invalidateQueries({ queryKey: ['marketMove', payload.marketMoveId.toString()] });
            queryClient.invalidateQueries({ queryKey: ['marketMoveRun', payload.marketMoveRunId.toString()] });
            
            options.onSuccess?.(response);
        } catch(error) {
            if (axios.isAxiosError(error) && error.response?.status === 304) {
                console.log('No changes were made to the Market Move (304 status received)');
                enqueueSnackbar('No changes were made to the Market Move.', { variant: 'info' });
                options.onSuccess?.(error.response); // Treat 304 as a success case
            } else {
                console.error(`Error ${requestTo === 'CANCELMM' ? 'canceling Market Move' : 'removing stores from Market Move'}:`, error);
                enqueueSnackbar(
                    requestTo === 'CANCELMM'
                    ? 'Failed to cancel Market Move'
                    : 'Failed To remove Selected stores from Market Move',
                    { variant: 'error'}
                );
                options.onError?.(error);
            }
        }
    };

    return { cancelMarketMove };
}