import { PriceMode } from "../interfaces/enums";

export const formatValue = (
  val: number | null | undefined,
  priceMode: PriceMode,
  isOverridden: boolean
): string => {
  if (val === null || val === undefined || isNaN(Number(val))) {
    return "N/A";
  }

  const numVal = Number(val);
  let formattedValue: string;

  if (priceMode === PriceMode.ABSOLUTE || isOverridden) {
    formattedValue = numVal.toFixed(3);
  } else {
    formattedValue = numVal.toFixed(2);
    formattedValue = formattedValue.endsWith("9")
      ? formattedValue.slice(0, -1) + "99"
      : formattedValue + "9";
  }

  return formattedValue;
};