import { TextField, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";

interface PriceFieldProps {
  price?: number | null;
  setPrice: (value: number | null) => void;
  label: string | undefined;
  disabled: boolean;
  min?: number;
  max?: number;
  allowNA?: boolean;
  error?: boolean;
  errorMessage?: string;
  active?: boolean;
  allowDecimals?: boolean; // Add this line
  precision?: number; // Add this line
  allowNegative?: boolean; // Add this line
}

function PriceField({
  price,
  setPrice,
  label,
  disabled,
  min,
  max,
  allowNA = true,
  error = false,
  errorMessage = '',
  active = false,
  allowDecimals = true, 
  precision = 3,
  allowNegative = false,
}: Readonly<PriceFieldProps>) {
  const [displayValue, setDisplayValue] = useState<string>("N/A");

  useEffect(() => {
    if (price !== undefined && price !== null) {
      setDisplayValue(price.toFixed(precision));
    } else {
      setDisplayValue(allowNA ? "N/A" : "");
    }
  }, [price, allowNA, precision]); // Add precision to the dependency array

  const handleFocus = () => {
    if (!disabled && displayValue === "N/A") {
      setDisplayValue("");
    }
  };

  const handleBlur = () => {
    submitValue();
  };

  const submitValue = () => {
    if (displayValue === "") {
      setDisplayValue(allowNA ? "N/A" : "0".padEnd(precision + 2, '0'));
      setPrice(allowNA ? null : 0);
      return;
    }
    let newValue = parseFloat(displayValue);
    if (!isNaN(newValue)) {
      let formattedValue: string;
      if (allowDecimals) {
        const parts = displayValue.split('.');
        if (parts.length === 2 && parts[1].length === 2) {
          // If there are exactly 2 decimal places, add '9' as the third
          formattedValue = `${parts[0]}.${parts[1]}9`;
        } else {
          formattedValue = newValue.toFixed(precision);
        }
      } else {
        formattedValue = newValue.toFixed(0);
      }
      console.debug("formattedValue", formattedValue);
      setPrice(parseFloat(formattedValue));
      setDisplayValue(formattedValue);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // known issue: value does not include the minus sign by itself. It will show as an empty 
    const { value } = e.target;
    
    // Prevent decimal input if not allowed
    if (!allowDecimals && value.includes('.')) {
      return;
    }

    if (value === "") {
      setDisplayValue(allowNA ? "N/A" : value);
      if (allowNA) {
        setPrice(null);
      }
      return;
    }

    const numValue = parseFloat(value);
    if (isNaN(numValue)) {
      return;
    }

    // Allow up to 'precision' decimal places
    const parts = value.split(".");
    if (parts.length > 2 || (parts.length === 2 && parts[1].length > precision)) {
      return;
    }

    setDisplayValue(value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "." && !allowDecimals) {
      e.preventDefault();
    }
    if (e.key === "Enter") {
      e.preventDefault();
      submitValue();
    }
  };

  const textField = (
    <TextField
      label={label}
      value={displayValue}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      type="number"
      size="small"
      inputProps={{
        readOnly: disabled,
        min,
        max,
        step: !allowDecimals ? "1" : "0.01",
      }}
      placeholder="N/A"
      onWheel={(e) => e.preventDefault()}
      InputLabelProps={{
        shrink: true,
      }}
      disabled={disabled}
      error={error || (!allowNA && (price === null || price === undefined || price === 0))}
      sx={{
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: active ? 'green' : 'inherit',
            borderWidth: active ? 2 : 1,
          }, 
        },
        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
        '& input[type=number]': {
          '-moz-appearance': 'textfield',
        },
      }}
    />
  );

  return error && errorMessage ? (
    <Tooltip title={errorMessage} placement="top">
      <div>{textField}</div>
    </Tooltip>
  ) : (
    textField
  );
}

export default PriceField;
