import DoneAllIcon from "@mui/icons-material/DoneAll";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { Box, Button, Tooltip } from "@mui/material";
import { RunStatus } from "../../../../interfaces/enums";

interface TableToolbarProps {
  runStatus: RunStatus;
  handleToggleAll: () => void;
  isExpanded: boolean;
  onToggleExpand: () => void;
  finalizeSelection: () => void;
}

function StoreListTableToolbar({
  runStatus,
  handleToggleAll,
  isExpanded,
  onToggleExpand,
  finalizeSelection,
}: Readonly<TableToolbarProps>) {
  const handleClick = () => {
    if (isExpanded) {
      finalizeSelection();
    }
    onToggleExpand();
  };

  return (
    <Box sx={{ display: "flex", gap: "16px", flexWrap: "wrap" }}>
      {/*<Tooltip*/}
      {/*  title={isExpanded ? "Hide selected stores" : "Show selected stores"}*/}
      {/*>*/}
      {/*  <Button*/}
      {/*    onClick={handleClick}*/}
      {/*    startIcon={isExpanded ? <DoneAllIcon /> : <PlaylistAddIcon />}*/}
      {/*    variant="outlined"*/}
      {/*    size="small"*/}
      {/*    disabled={runStatus === RunStatus.INPROGRESS}*/}
      {/*  >*/}
      {/*    {isExpanded ? "Finalize Selection" : "Add/Remove Stores"}*/}
      {/*  </Button>*/}
      {/*</Tooltip>*/}
    </Box>
  );
}

export default StoreListTableToolbar;
