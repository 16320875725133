import {
  UseQueryOptions,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
  UseInfiniteQueryResult,
  useInfiniteQuery,
} from "@tanstack/react-query";
import {
  GetMMListParams,
  createMarketMove,
  getMMList,
  getSingleMM,
  updateMarketMove,
} from "../api/marketMoveMetaDataApi";
import { MarketMoveMetaDataSchema } from "../schema/schemas";
import { enqueueSnackbar } from "notistack";
import { AxiosError } from "axios";

export const useGetMMList = (
  params?: GetMMListParams,
  options?: Omit<
    UseQueryOptions<MarketMoveMetaDataSchema[], Error>,
    "queryKey" | "queryFn"
  >
): UseQueryResult<MarketMoveMetaDataSchema[], Error> => {
  return useQuery({
    queryKey: ["marketMoves", params],
    queryFn: () => getMMList(params),
    keepPreviousData: true,
    staleTime: 60000,
    ...options,
  });
};

export const useInfiniteMarketMoves = (
  params?: GetMMListParams
): UseInfiniteQueryResult<{ data: MarketMoveMetaDataSchema[], nextOffset: number | undefined }, Error> => {
  const fetchMarketMoves = async ({ pageParam = 0 }) => {
    const newParams = { ...params, offset: pageParam };
    const result = await getMMList(newParams);
    return { data: result, nextOffset: result.length > 0 ? pageParam + result.length : undefined };
  };

  return useInfiniteQuery(
    ["marketMoves", params],
    fetchMarketMoves,
    {
      getNextPageParam: (lastPage) => lastPage.nextOffset,
      keepPreviousData: true,
    }
  );
};

export const useCreateMarketMove = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (marketMove: MarketMoveMetaDataSchema) =>
      createMarketMove(marketMove),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["marketMoves"] });
      enqueueSnackbar("Market move created successfully", { variant: "success" });
    },
    onError: (error: AxiosError) => {
      let errorMessage = "An unexpected error occurred";
      if (error.response) {
        const responseData = error.response.data;
        if (typeof responseData === 'string') {
          // Extract the relevant part of the error message
          const match = responseData.match(/Key \(market_move_name\)=\((.*?)\) already exists/);
          if (match) {
            errorMessage = `(${match[1]}) already exists`;
          } else {
            errorMessage = responseData;
          }
        } else if (typeof responseData === 'object' && responseData !== null && 'message' in responseData) {
          errorMessage = String(responseData.message);
        }
      } else if (error.request) {
        errorMessage = "No response received from server";
      } else {
        errorMessage = error.message;
      }
      enqueueSnackbar(`Error creating market move: ${errorMessage}`, { variant: "error" });
    },
  });
};

export const useUpdateMarketMove = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (marketMove: MarketMoveMetaDataSchema) =>
      updateMarketMove(marketMove),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: ["marketMoves"] });
      queryClient.invalidateQueries({
        queryKey: ["marketMove", variables.marketMoveId],
      });
      queryClient.invalidateQueries({
        queryKey: ["marketMoveRun", variables.marketMoveRunId?.toString()],
      });
    },
  });
};

export const useGetSingleMM = (mmId: string | null) => {
  return useQuery({
    queryKey: ["marketMove", mmId],
    queryFn: () => getSingleMM(mmId),
    enabled: !!mmId,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      console.debug("useGetSingleMM onSuccess", data);
    },
    onError: (error: AxiosError) => {
      console.error("useGetSingleMM onError", error);
      enqueueSnackbar(`Error fetching market move: ${error.message}`, { variant: "error" });
    },
    // cacheTime: 0,
  });
};
