import { useState, useRef } from "react";
import useLocalStorage from "./useLocalStorage";

export const useMarketMoveWindowSettings = () => {
  const [fullscreen, setFullscreen] = useLocalStorage<boolean>(
    "mm-ac-fullscreen",
    false
  );
  const [position, setPosition] = useLocalStorage<{ x: number; y: number }>(
    "mm-ac-position",
    { x: 0, y: 0 }
  );
  const [size, setSize] = useLocalStorage<{ width: number; height: number }>(
    "mm-ac-size",
    { width: 800, height: 600 }
  );
  const [dragCursor, setDragCursor] = useState(false);
  const lastNonFullscreenPosition = useRef(position);

  const toggleFullScreen = () => {
    setFullscreen((prev) => {
      if (prev) {
        // Exiting fullscreen, restore the last non-fullscreen position
        setPosition(lastNonFullscreenPosition.current);
      } else {
        // Entering fullscreen, save the current position
        lastNonFullscreenPosition.current = position;
        setPosition({ x: 0, y: 0 });
      }
      return !prev;
    });
  };

  const handleOnDrag = (e, ui) => {
    const { x, y } = ui;
    setPosition({ x, y });
    if (!fullscreen) {
      lastNonFullscreenPosition.current = { x, y };
    }
  };

  const handleOnStart = () => {
    setDragCursor(true);
  };

  const handleOnEnd = () => {
    setDragCursor(false);
  };

  const onResize = (event, { size }) => {
    setSize({ width: size.width, height: size.height });
  };

  const resetPosition = () => {
    const newPosition = { x: 0, y: 0 };
    setPosition(newPosition);
    lastNonFullscreenPosition.current = newPosition;
  };

  return {
    fullscreen,
    position,
    size,
    dragCursor,
    toggleFullScreen,
    handleOnDrag,
    handleOnStart,
    handleOnEnd,
    onResize,
    resetPosition,
  };
};