import constants from "../constants/constants.json";

export function getUserName() {
  return sessionStorage.getItem(constants.USER_NAME_KEY) || "UNKNOWN USER";
}

function debounce<T extends (...args: any[]) => any>(
  func: T,
  wait: number
): (...args: Parameters<T>) => void {
  let timeout: NodeJS.Timeout | null = null;
  return (...args: Parameters<T>) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
}


