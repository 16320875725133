import React from "react";
import { Menu, MenuItem } from "@mui/material";
import {
  ContentCopy as ContentCopyIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { useInfiniteQuery } from '@tanstack/react-query';
// import InfiniteScroll from 'react-infinite-scroller';

interface MarketMoveListMenuProps {
  anchorEl: HTMLElement | null;
  handleMenuClose: () => void;
  handleClone: () => void;
  handleDelete: () => void;
}

const MarketMoveListMenu: React.FC<MarketMoveListMenuProps> = ({
  anchorEl,
  handleMenuClose,
  handleClone,
  handleDelete,
}) => (
  <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
    {/* <MenuItem onClick={handleClone}>
      <ContentCopyIcon fontSize="small" />
      Create New MM w/ Storelist
    </MenuItem> */}
    {/* <MenuItem onClick={handleDelete}>
      <DeleteIcon fontSize="small" sx={{ color: "error.main" }} />
      Delete
    </MenuItem> */}
  </Menu>
);

export default MarketMoveListMenu;
