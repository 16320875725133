import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo, useState } from "react";
import { useWatch } from "react-hook-form";
import { useGetMMList } from "../../../hooks/marketMoveMetaDataApiHooks";
import { prefetchSingleMMRun } from "../../../hooks/marketMoveRunApiHooks";
import { RunStatus } from "../../../interfaces/enums";
import { MarketMoveMetaDataSchema } from "../../../schema/schemas";
import { useMarketMoveMetaDataMethods } from "../../../store/formStore";
import useGeneralStore from "../../../store/generalStore";
import useMarketMoveStore from "../../../store/marketMoveStore";

// Utility functions
export const filterMarketMoves = (
  moves: MarketMoveMetaDataSchema[],
  showExpired: boolean,
  onlyPastExecutions: boolean,
  searchValue: string
) => {
  const today = new Date();
  return moves.filter(
    (move) =>
      (showExpired || new Date(move.endDate ?? "") >= today) &&
      (!onlyPastExecutions ||
        move.runStatus == "CANCELED" ||
        move.runStatus == "EXECUTED") &&
      (move.marketMoveName.toLowerCase().includes(searchValue.toLowerCase()) ||
        move.createdBy?.toLowerCase().includes(searchValue.toLowerCase()))
  );
};

export const filterAndSortMarketMoves = (
  moves: MarketMoveMetaDataSchema[],
  sortMode: "startDate" | "modifiedDate",
  sortOrder: "asc" | "desc"
) => {
  const sortedMoves = [...moves].sort((a, b) => {
    const valueA = new Date(a[sortMode] || "").getTime();
    const valueB = new Date(b[sortMode] || "").getTime();
    return sortOrder === "desc" ? valueB - valueA : valueA - valueB;
  });

  const movesWithoutSortMode = moves.filter((move) => !move[sortMode]);
  return [...movesWithoutSortMode, ...sortedMoves];
};

// Smaller, focused hooks
export const useMarketMoveListState = () => {
  const [showExpired, setShowExpired] = useState(true);
  const [onlyPastExecutions, setOnlyPastExecutions] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [sortMode, setSortMode] = useState<"startDate" | "modifiedDate">(
    "modifiedDate"
  );
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");

  const handleFilterClick = useCallback(() => {
    setShowExpired((prev) => !prev);
  }, []);

  const setOnlyPastExecutionsState = useCallback((newValue) => {
    setOnlyPastExecutions(newValue);
  }, []);

  const handleSortModeChange = useCallback(() => {
    setSortMode((prev) =>
      prev === "modifiedDate" ? "startDate" : "modifiedDate"
    );
  }, []);

  const handleSortOrderChange = useCallback(() => {
    setSortOrder((prev) => (prev === "asc" ? "desc" : "asc"));
  }, []);

  return {
    showExpired,
    onlyPastExecutions,
    searchValue,
    sortMode,
    sortOrder,
    setSearchValue,
    setOnlyPastExecutionsState,
    handleFilterClick,
    handleSortModeChange,
    handleSortOrderChange,
  };
};

export const useFilteredMarketMoves = (
  data: MarketMoveMetaDataSchema[] | undefined,
  showExpired: boolean,
  onlyPastExecutions: boolean,
  searchValue: string,
  sortMode: "startDate" | "modifiedDate",
  sortOrder: "asc" | "desc",
  currentMarketMove: MarketMoveMetaDataSchema | undefined
) => {
  return useMemo(() => {
    const allMoves = [...(data || [])];
    let currentMove: MarketMoveMetaDataSchema | undefined = undefined;

    if (currentMarketMove?.marketMoveId === "") {
      currentMove = currentMarketMove;
    }

    const filteredMoves = filterMarketMoves(
      allMoves,
      showExpired,
      onlyPastExecutions,
      searchValue
    );
    const sortedMoves = filterAndSortMarketMoves(
      filteredMoves,
      sortMode,
      sortOrder
    );

    return currentMove ? [currentMove, ...sortedMoves] : sortedMoves;
  }, [
    data,
    currentMarketMove?.marketMoveId,
    showExpired,
    onlyPastExecutions,
    searchValue,
    sortMode,
    sortOrder,
  ]);
};

// Main hook
export const useMarketMoveToggle = () => {
  const {
    showExpired,
    onlyPastExecutions,
    searchValue,
    sortMode,
    sortOrder,
    setSearchValue,
    handleFilterClick,
    setOnlyPastExecutionsState,
    handleSortModeChange,
    handleSortOrderChange,
  } = useMarketMoveListState();

  const { showRecap } = useGeneralStore();

  const {
    selectedMarketMoveId,
    setSelectedMarketMoveId,
    reset: resetMarketMoveStore,
  } = useMarketMoveStore();
  const marketMoveMetaDataMethods = useMarketMoveMetaDataMethods();
  const { control } = marketMoveMetaDataMethods ?? {};
  const { data, isFetching, isError, error } = useGetMMList(undefined, {
    enabled: !showRecap,
  });
  const { isDirty } = marketMoveMetaDataMethods?.formState ?? {};
  const currentMarketMove = useWatch<MarketMoveMetaDataSchema>({
    control: control,
  });

  const filteredMarketMoves = useFilteredMarketMoves(
    data,
    showExpired,
    onlyPastExecutions,
    searchValue,
    sortMode,
    sortOrder,
    currentMarketMove
  );

  const queryClient = useQueryClient();
  const showPastMarketMoves = useGeneralStore(
    (state) => state.showPastMarketMoves
  );

  const handleMarketMoveChange = useCallback(
    (_event: React.MouseEvent<HTMLElement>, newMarketMoveId: string | null) => {
      resetMarketMoveStore(() => {
        if (newMarketMoveId !== selectedMarketMoveId && !isDirty) {
          // Prefetch logic
          if (newMarketMoveId && data) {
            const selectedMove = data.find(
              (move) => move.marketMoveId === newMarketMoveId
            );
            if (
              selectedMove?.marketMoveRunId &&
              selectedMove.runStatus !== RunStatus.CANCELLED &&
              selectedMove.runStatus !== RunStatus.EXECUTED
            ) {
              prefetchSingleMMRun(
                queryClient,
                selectedMove.marketMoveRunId,
                showPastMarketMoves
              );
            }
          }
          setSelectedMarketMoveId(newMarketMoveId);
        }
      });
    },
    [
      isDirty,
      setSelectedMarketMoveId,
      selectedMarketMoveId,
      resetMarketMoveStore,
      data,
      queryClient,
      showPastMarketMoves,
    ]
  );

  return {
    state: {
      showExpired,
      onlyPastExecutions,
      searchValue,
      selectedMarketMoveId,
      filteredMarketMoves,
    },
    getMMList: {
      data,
      isFetching,
      isError,
      error,
    },
    handlers: {
      handleMarketMoveChange,
      handleFilterClick,
      setOnlyPastExecutionsState,
      setSearchValue,
    },
    display: {
      sortMode,
      sortOrder,
      handleSortModeChange,
      handleSortOrderChange,
    },
  };
};
