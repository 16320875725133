import React from "react";
import {
  Stepper,
  Step,
  StepLabel,
  styled,
  StepIconProps,
  Tooltip,
  Box,
} from "@mui/material";
import { RunStatus } from "../../interfaces/enums";
import EditNoteIcon from "@mui/icons-material/EditNote";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import SendIcon from "@mui/icons-material/Send";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import useGeneralStore from "../../store/generalStore";
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

const CustomStepLabel = styled(StepLabel)<{
  active?: boolean;
  completed?: boolean;
  greyedOut?: boolean;
}>(({ active, completed, greyedOut }) => ({
  "& .MuiStepLabel-label": {
    fontSize: "0.850rem",
    marginTop: "4px !important",
    fontWeight: active ? "bolder !important" : "normal",
    color: greyedOut ? "#9e9e9e" : (completed ? "#757575" : "#000000"),
  },
  // "& .MuiStepLabel-label.Mui-active": {
  //   fontWeight: "bold",
  //   color: greyedOut ? "#9e9e9e" : "#000000",
  // },
}));

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: 'linear-gradient( 95deg,rgb(0,255,0) 0%,rgb(0,200,0) 50%,rgb(0,150,0) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: 'linear-gradient( 95deg,rgb(0,255,0) 0%,rgb(0,200,0) 50%,rgb(0,150,0) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean; cancelled?: boolean };
}>(({ ownerState }) => ({
  zIndex: 1,
  color: "#bdbdbd",
  ...(ownerState.active && {
    color: ownerState.cancelled ? "#f44336" : "#2196F3",
  }),
  ...(ownerState.completed && {
    color: "#4caf50",
  }),
}));

const CustomStepIcon = (props: StepIconProps & { cancelled?: boolean; greyedOut?: boolean }) => {
  const { active, completed, className, icon, cancelled, greyedOut } = props;

  const iconSize = "2rem";

  const icons: { [index: string]: React.ReactElement } = {
    1: <EditNoteIcon sx={{ fontSize: iconSize }} />,
    2: <ScheduleSendIcon sx={{ fontSize: iconSize }} />,
    3: <SendIcon sx={{ fontSize: iconSize }} />,
    4: cancelled ? (
      <CancelScheduleSendIcon sx={{ fontSize: iconSize }} />
    ) : (
      <DoneAllIcon sx={{ fontSize: iconSize }} />
    ),
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active, cancelled }}
      className={className}
      style={{ color: greyedOut ? "#9e9e9e" : undefined }}
    >
      {icons[String(icon)]}
    </ColorlibStepIconRoot>
  );
};

const FullWidthStepper = styled(Stepper)(({ theme }) => ({
  width: "80%",
  "& .MuiStepConnector-line": {
    minWidth: "65px",
  },
}));

const steps = [
  { status: RunStatus.DRAFT, label: "Draft", description: "Initial state" },
  {
    status: RunStatus.SIGNEDOFF,
    label: "Submitted",
    description: "Approved for execution",
  },
  {
    status: RunStatus.INPROGRESS,
    label: "Active",
    description: "Currently applied to the included stores and products",
  },
  {
    status: RunStatus.EXECUTED,
    label: "Completed",
    description: "Market Move has successfully completed and PriceGen has resumed",
  },
];

interface LifeCycleStepperProps {
  runStatus: RunStatus;
}

const LifeCycleStepper: React.FC<LifeCycleStepperProps> = ({ runStatus }) => {
  const isCancelled = runStatus === RunStatus.CANCELLED;
  const showPastMarketMoves = useGeneralStore((state) => state.showPastMarketMoves);

  // Clone and modify steps based on the run status
  const modifiedSteps = [...steps];

  if (isCancelled) {
    // Replace the last step with "Cancelled"
    modifiedSteps[modifiedSteps.length - 1] = {
      status: RunStatus.CANCELLED,
      label: "Cancelled",
      description: "Terminated early",
    };
  }

  const getStepIndex = (status: RunStatus) => {
    return modifiedSteps.findIndex((step) => step.status === status);
  };

  const activeStep = getStepIndex(runStatus);

  return (
    <Box sx={{ width: "auto", overflowX: "auto" }}>
      <FullWidthStepper activeStep={activeStep} connector={<ColorlibConnector />}>
        {modifiedSteps.map((step, index) => (
          <Step key={step.status} completed={index < activeStep}>
            <Tooltip title={step.description} arrow>
              <CustomStepLabel
                active={index === activeStep}
                completed={index < activeStep}
                greyedOut={showPastMarketMoves && index < modifiedSteps.length - 1}
                StepIconComponent={(props) => (
                  <CustomStepIcon
                    {...props}
                    cancelled={
                      isCancelled &&
                      index === modifiedSteps.length - 1
                    }
                    greyedOut={showPastMarketMoves && index < modifiedSteps.length - 1}
                  />
                )}
              >
                {step.label}
              </CustomStepLabel>
            </Tooltip>
          </Step>
        ))}
      </FullWidthStepper>
    </Box>
  );
};

export default LifeCycleStepper;
