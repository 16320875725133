import { Box, IconButton, Typography, Menu, MenuItem, Tab, Tabs } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/system";
import { useState, MouseEvent } from "react";
import useGeneralStore from "../../store/generalStore";

// Define the Header styled component
const Header = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(0.25, 1),
  minHeight: 32,
  borderBottom: `0.5px solid ${theme.palette.divider}`,
}));


const ActionCardHeader = ({ fullscreen, dragCursor, toggleFullScreen, handleClose, resetPosition }) => {
  const theme = useTheme();
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const [value, setValue] = useState(0);
  const { setShowPastMarketMoves, toggleRecap, showRecap } = useGeneralStore();
  const handleChange = (event, newValue) => {
    console.log(newValue);
    setValue(newValue);
    switch (newValue){
      case 0:
        setShowPastMarketMoves(false);
        break;
      case 1:
        setShowPastMarketMoves(true);
        if(showRecap){toggleRecap();}
        break;
    }
  };

  const modes = [
    {
      name: "Market Move Homepage"
    },
    {
      name: "Past Market Moves"
    }
  ];

  const handleContextMenu = (event: MouseEvent) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          }
        : null,
    );
  };

  const handleCloseMenu = () => {
    setContextMenu(null);
  };

  const handleResetPosition = () => {
    resetPosition();
    handleCloseMenu();
  };

  return (
    <Header
      className="popup-header"
      onContextMenu={handleContextMenu}
      sx={{
        cursor: fullscreen ? "default" : dragCursor ? "grabbing" : "grab",
      }}
    >
      <Typography variant="h5">Market Move</Typography>
      <Box>
        <Tabs value={value} onChange={handleChange}>
          {modes.map((mode, i) => (
            <Tab key={i} label={mode.name} />
          ))}
        </Tabs>
      </Box>
      <Box
        sx={{
          minWidth: 64,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <IconButton onClick={toggleFullScreen} title="Toggle fullscreen" size="small">
          {fullscreen ? (
            <FullscreenExitIcon sx={{ color: theme.palette.secondary.main }} />
          ) : (
            <FullscreenIcon sx={{ color: theme.palette.primary.main }} />
          )}
        </IconButton>
        <IconButton onClick={handleClose} title="Close the Action Panel" size="small">
          <CloseIcon sx={{ color: theme.palette.error.main }} />
        </IconButton>
      </Box>
      <Menu
        open={contextMenu !== null}
        onClose={handleCloseMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={handleResetPosition}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <RestartAltIcon sx={{ mr: 1 }} />
            Reset Position
          </Box>
        </MenuItem>
      </Menu>
    </Header>
  );
};

export default ActionCardHeader;