import {
  Box,
  Button,
  CircularProgress,
  List,
  Modal,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useInfiniteMarketMoves } from "../../../hooks/marketMoveMetaDataApiHooks";
import { RunStatus } from "../../../interfaces/enums";
import { useFormStore } from "../../../store/formStore";
import useMarketMoveStore from "../../../store/marketMoveStore";
import { generateDefaultMarketMove } from "../../../utils/marketMoveUtils";
import UnsavedChangesDialog from "../../shared/UnsavedChanges";
import ErrorMessage from "./ErrorMessage";
import MarketMoveListActions from "./MarketMoveListActions";
import MarketMoveListItem from "./MarketMoveListItem";
import MarketMoveListMenu from "./MarketMoveListMenu";
import MarketMoveListSearch from "./MarketMoveListSearch";
import { useMarketMoveToggle } from "./useMarketMoveToggle";

const MarketMoveToggle: React.FC = () => {
  const {
    state: toggleState,
    handlers: toggleHandlers,
    display,
  } = useMarketMoveToggle();

  // State declarations
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuMoveId, setMenuMoveId] = useState<string | null>(null);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [hideSearchTimeout, setHideSearchTimeout] =
    useState<NodeJS.Timeout | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [pendingMarketMoveId, setPendingMarketMoveId] = useState<string | null>(
    null
  );
  const [reachedEnd, setReachedEnd] = useState(false);


  // Hooks
  const {
    setSelectedMarketMoveId,
    incrementMMMetaDataSaveReq,
    incrementMMRunSaveRequest,
    setShouldFetchPreview,
  } = useMarketMoveStore();

  const {
    marketMoveMetaDataMethods: metaDataMethods,
    marketMoveRunMethods: {
      watch: mmRunWatch, // Add this line to destructure watch
    },
  } = useFormStore();

  const marketMoveRunId = mmRunWatch('marketMoveRun.marketMoveRunId');
  const marketMoveRunRecapDetails = mmRunWatch('marketMoveRunRecapDetails');

  const { reset, formState } = metaDataMethods;
  const { isDirty } = formState;

  // Infinite scroll hook
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isError,
    error
  } = useInfiniteMarketMoves({
    limit: 100,
    sortBy: display.sortMode,
    asc: display.sortOrder === "asc",
  });
  

  // Event handlers
  const handleMarketMoveChangeWithDialog = useCallback(
    (event: React.MouseEvent<HTMLElement>, newMarketMoveId: string | null) => {
      if (isDirty) {
        setPendingMarketMoveId(newMarketMoveId);
        setIsDialogOpen(true);
      } else {
        toggleHandlers.handleMarketMoveChange(event, newMarketMoveId);
      }
    },
    [isDirty, toggleHandlers.handleMarketMoveChange]
  );

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  useEffect(() => {
    if (!hasNextPage && data?.pages[data.pages.length - 1]?.data.length === 0) {
      setReachedEnd(true);
    }
  }, [data, hasNextPage]);

  const handleDialogSave = () => {
    const shouldSaveRun =
      marketMoveRunRecapDetails?.length > 0;

    if (shouldSaveRun) {
      setShouldFetchPreview(true, () => {
        incrementMMMetaDataSaveReq(RunStatus.DRAFT, false, () => {
          incrementMMRunSaveRequest(
            RunStatus.DRAFT,
            marketMoveRunId, // Use the watched marketMoveRunId
            updateUIAfterSave
          );
        });
      });
    } else {
      incrementMMMetaDataSaveReq(RunStatus.DRAFT, false, updateUIAfterSave);
    }
  };

  const updateUIAfterSave = () => {
    setIsDialogOpen(false);
    setSelectedMarketMoveId(pendingMarketMoveId);
    setPendingMarketMoveId(null);
  };

  const handleDialogDiscard = () => {
    console.debug("handleDialogDiscard");
    reset(null);
    setIsDialogOpen(false);
    setSelectedMarketMoveId(pendingMarketMoveId);
  };

  const handleSearchIconClick = () => {
    setIsSearchVisible(true);
  };

  const handleSearchBlur = () => {
    if (toggleState.searchValue.trim() === "") {
      const timeout = setTimeout(() => {
        setIsSearchVisible(false);
      }, 5000);
      setHideSearchTimeout(timeout);
    }
  };

  const handleSearchFocus = () => {
    if (hideSearchTimeout) {
      clearTimeout(hideSearchTimeout);
      setHideSearchTimeout(null);
    }
  };

  const handleAddClick = useCallback(() => {
    console.debug("handleAddClick");
    const newMarketMove = generateDefaultMarketMove();
    newMarketMove.marketMoveId = "";
    reset(newMarketMove);
    setSelectedMarketMoveId("");
  }, [reset, setSelectedMarketMoveId]);

  const handleWarningModalClose = () => {
    setIsWarningModalOpen(false);
  };

  const handleMenuOpen = useCallback(
    (event: React.MouseEvent<HTMLElement>, moveId: string) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
      setMenuMoveId(moveId);
    },
    []
  );

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuMoveId(null);
  };

  const handleClone = () => {
    console.debug("handleClone", menuMoveId);
    handleMenuClose();
  };

  const handleDelete = () => {
    handleMenuClose();
  };

  const handleScroll = useCallback((event: React.UIEvent<HTMLElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    if (
      scrollHeight - scrollTop - clientHeight < 0.25 * scrollHeight &&
      hasNextPage &&
      !isFetchingNextPage
    ) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  // Render methods
  const renderWarningModal = () => {
    const style = {
      position: "absolute" as const,
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      bgcolor: "background.paper",
      border: "2px solid #000",
      boxShadow: 24,
      p: 4,
    };

    return (
      <Modal
        open={isWarningModalOpen}
        onClose={handleWarningModalClose}
        aria-labelledby="warning-modal-title"
        aria-describedby="warning-modal-description"
      >
        <Box sx={style}>
          <Typography id="warning-modal-title" variant="h6" component="h2">
            Warning
          </Typography>
          <Typography id="warning-modal-description" sx={{ mt: 2 }}>
            You have an unsaved market move draft. Please complete or discard it
            before adding a new one.
          </Typography>
          <Button onClick={handleWarningModalClose} sx={{ mt: 2 }}>
            Okay
          </Button>
        </Box>
      </Modal>
    );
  };

  if (isError) {
    return <ErrorMessage error={error} />;
  }

  return (
    <div>
      <MarketMoveListActions
        handleAddClick={handleAddClick}
        handleFilterClick={toggleHandlers.handleFilterClick}
        handleSortModeChange={display.handleSortModeChange}
        handleSortOrderChange={display.handleSortOrderChange}
        showExpired={toggleState.showExpired}
        onlyPastExecutions={false}
        sortMode={display.sortMode}
        sortOrder={display.sortOrder}
      />
      <MarketMoveListSearch
        marketMoves={toggleState.filteredMarketMoves}
        isSearchVisible={isSearchVisible}
        searchValue={toggleState.searchValue}
        handleSearchIconClick={handleSearchIconClick}
        handleSearchBlur={handleSearchBlur}
        handleSearchFocus={handleSearchFocus}
        setSearchValue={toggleHandlers.setSearchValue}
      />
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <Box
            sx={{
              height: "30vh",
              maxHeight: "30vh",
              overflowY: "auto",
              overflowX: "hidden",
            }}
            onScroll={handleScroll}
          >
            <List sx={{ width: "100%", padding: 0 }}>
              {toggleState.filteredMarketMoves.map((move) => (
                <MarketMoveListItem
                  key={move.marketMoveId}
                  move={move}
                  selectedMarketMoveId={toggleState.selectedMarketMoveId}
                  handleMarketMoveChange={handleMarketMoveChangeWithDialog}
                  handleMenuOpen={handleMenuOpen}
                />
              ))}
            </List>
            {isFetchingNextPage && <CircularProgress />}
            {reachedEnd && (
              <Typography variant="body2" sx={{ textAlign: 'center', py: 2 }}>
                No more market moves to load.
              </Typography>
            )}
          </Box>
          <MarketMoveListMenu
            anchorEl={anchorEl}
            handleMenuClose={handleMenuClose}
            handleClone={handleClone}
            handleDelete={handleDelete}
          />
        </>
      )}
      <UnsavedChangesDialog
        isOpen={isDialogOpen}
        onClose={handleDialogClose}
        onDiscard={handleDialogDiscard}
        onSave={handleDialogSave}
        showSaveButton={false}
      />
      {renderWarningModal()}
    </div>
  );
};

export default MarketMoveToggle;