import DeleteIcon from "@mui/icons-material/Delete";
import {
  Checkbox,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
  capitalize,
} from "@mui/material";
import { get } from "lodash"; // Add this import
import {
  MRT_ColumnDef,
  MRT_Row,
  MaterialReactTable,
  useMaterialReactTable
} from "material-react-table";
import React, { useMemo } from "react";
import { Controller } from "react-hook-form";
import { PriceMode, RunStatus } from "../../interfaces/enums";
import {
  MarketMoveProductConfigSchema
} from "../../schema/schemas";
import { useFormStore } from "../../store/formStore";
import useGeneralStore from "../../store/generalStore";
import useMarketMoveStore from "../../store/marketMoveStore";
import { tableTheme } from "../../theme/theme";
import PriceField from "./PriceField";

interface ProductSettingsTableProps {
  deleteProduct: (product: string) => void;
}

type ProductConfigField = keyof MarketMoveProductConfigSchema;

const PRODUCT_CONFIG_FIELDS = {
  PRICE_MODE: "priceMode",
  PRICE_INCREMENT: "priceIncrement",
  ABS_PRICE: "absPrice",
  EXCLUDE_PRICE_BELOW: "excludePriceBelow",
  EXCLUDE_PRICE_ABOVE: "excludePriceAbove",
  APPLY_LINKED_PRODUCTS: "applyLinkedProducts",
  BASE_MIN_PRICE: "baseMinPrice",
} as const;

const ProductSettingsTable: React.FC<ProductSettingsTableProps> = ({
  deleteProduct,
}) => {
  const {
    setValue,
      control,
    formState: { errors },
    watch: marketMoveMetaDataWatch,
  } = useFormStore((state) => state.marketMoveMetaDataMethods);
  const disableEditing = useMarketMoveStore((state) => state.disableEditing);
  // const currentMarketMove = useWatch<MarketMoveMetaDataSchema>({ control });
  const uniqueProducts = useGeneralStore((state) => state.uniqueProducts);

  const products = marketMoveMetaDataWatch("productConfigList") || [];
  const runStatus = marketMoveMetaDataWatch("runStatus"); 

  const renderProductCell = ({
    row,
  }: {
    row: MRT_Row<MarketMoveProductConfigSchema>;
  }) => {
    const productInfo = uniqueProducts.find(
      (p) => p.id === row.original.productId
    );
    return (
      <div style={{ fontSize: "12px", padding: "0rem 0.5rem" }}>
        {productInfo?.id} - {capitalize(productInfo?.name || "")}
      </div>
    );
  };

  const setProductConfigValue = (
    index: number,
    field: ProductConfigField,
    value: any
  ) => {
    setValue(`productConfigList.${index}.${field}`, value, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const renderCombinedPriceModeCell = ({
    row,
  }: {
    row: MRT_Row<MarketMoveProductConfigSchema>;
  }) => {
    const index = products.findIndex(
      (p) => p.productId === row.original.productId
    );

    return (
      <Controller
        control={control}
        name={`productConfigList.${index}.priceMode`}
        render={({ field }) => (
          <Select
          {...field}
            value={row.original.priceMode}
            disabled={disableEditing}
            size="small"
            style={{ minWidth: "120px", fontSize: "12px" }}
          >
            <MenuItem
              value={PriceMode.INCREMENTAL}
              style={{ fontSize: "12px" }}
            >
              Incremental Price Move
            </MenuItem>
            <MenuItem value={PriceMode.ABSOLUTE} style={{ fontSize: "12px" }}>
              Absolute Price Move
            </MenuItem>
          </Select>
        )}
      />
    );
  };

  const renderIncrementalPriceCell = ({
    row,
  }: {
    row: MRT_Row<MarketMoveProductConfigSchema>;
  }) => {
    const index = products.findIndex(
      (p) => p.productId === row.original.productId
    );
    const hasError = !!get(errors, `productConfigList.${index}.priceIncrement`);
    const errorMessage = get(
      errors,
      `productConfigList.${index}.priceIncrement.message`,
      ""
    );
    const isCurrentPriceMode = row.original.priceMode === PriceMode.INCREMENTAL;
    return (
      <Controller
        control={control}
        name={`productConfigList.${index}.priceIncrement`}
        render={({ field }) => (
          <PriceField
            price={row.original.priceIncrement}
            setPrice={(value) => {
              field.onChange(value);
            }}
            label={undefined}
            disabled={disableEditing || !isCurrentPriceMode}
            allowNA={!isCurrentPriceMode}
            min={-100}
            max={100}
            error={hasError}
            errorMessage={errorMessage}
            active={isCurrentPriceMode}
            allowDecimals={false}
            precision={0}
            allowNegative={true}
          />
        )}
      />
    );
  };

  const renderAbsolutePriceCell = ({
    row,
  }: {
    row: MRT_Row<MarketMoveProductConfigSchema>;
  }) => {
    const index = products.findIndex(
      (p) => p.productId === row.original.productId
    );
    const hasError = !!get(errors, `productConfigList.${index}.absPrice`);
    const errorMessage = get(
      errors,
      `productConfigList.${index}.absPrice.message`,
      ""
    );
    const isCurrentPriceMode = row.original.priceMode === PriceMode.ABSOLUTE;
    return (
      <Controller
        control={control}
        name={`productConfigList.${index}.absPrice`}
        render={({ field }) => (
          <PriceField
            price={row.original.absPrice}
            setPrice={(value) => {
              field.onChange(value);
            }}
            label={undefined}
            disabled={disableEditing || !isCurrentPriceMode}
            allowNA={isCurrentPriceMode}
            min={1}
            error={hasError}
            errorMessage={errorMessage}
            active={isCurrentPriceMode}
          />
        )}
      />
    );
  };

  const renderPriceFieldCell =
    (fieldName: "excludePriceBelow" | "excludePriceAbove") =>
    ({ row }: { row: MRT_Row<MarketMoveProductConfigSchema> }) => {
      const index = products.findIndex(
        (p) => p.productId === row.original.productId
      );
      return (
        <PriceField
          price={row.original[fieldName]}
          setPrice={(value) => {
            setProductConfigValue(index, fieldName, value);
          }}
          label=""
          disabled={disableEditing}
        />
      );
    };

  const renderApplyToLinkedCell = ({
    row,
  }: {
    row: MRT_Row<MarketMoveProductConfigSchema>;
  }) => {
    const index = products.findIndex(
      (p) => p.productId === row.original.productId
    );
    return (
      <Controller
        control={control}
        name={`productConfigList.${index}.applyLinkedProducts`}
        render={({ field }) => (
          <Checkbox
            {...field}
            disabled={disableEditing}
            checked={row.original.applyLinkedProducts || false}
            size="small"
          />
        )}
      />
    );
  };

  const renderBaseMinPriceCell = ({
    row,
  }: {
    row: MRT_Row<MarketMoveProductConfigSchema>;
  }) => {
    const index = products.findIndex(
      (p) => p.productId === row.original.productId
    );
    const isAbsoluteMode = row.original.priceMode === PriceMode.ABSOLUTE;
    return (
      <PriceField
        price={row.original.baseMinPrice}
        setPrice={(value) => {
          setProductConfigValue(
            index,
            PRODUCT_CONFIG_FIELDS.BASE_MIN_PRICE,
            value
          );
        }}
        label=""
        disabled={disableEditing || isAbsoluteMode}
      />
    );
  };

  const columns: MRT_ColumnDef<MarketMoveProductConfigSchema>[] = useMemo(
    () => [
      {
        id: "delete",
        header: "",
        maxSize: 10,
        grow: false,
        Cell: ({ row }) => (
          <Tooltip title="Delete Product">
            <span>
              <IconButton
                onClick={() => deleteProduct(row.original.productId)}
                disabled={
                  runStatus === RunStatus.INPROGRESS ||
                  disableEditing
                }
                size="small"
                color="error"
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
        ),
      },
      {
        accessorKey: "productId",
        header: "Product",
        size: 50,
        grow: false,
        Cell: renderProductCell,
      },
      {
        accessorKey: "priceMode",
        header: "Select Move Type",
        size: 50,
        Cell: renderCombinedPriceModeCell,
      },
      {
        accessorKey: "applyLinkedProducts",
        header: "Link Secondary Products",
        size: 100,
        Cell: renderApplyToLinkedCell,
      },
      {
        accessorKey: "absPrice",
        header: "Absolute Price Move",
        size: 80,
        Cell: renderAbsolutePriceCell,
      },
      {
        accessorKey: "excludePriceBelow",
        header: "Exclude Below",
        size: 50,
        Cell: renderPriceFieldCell("excludePriceBelow"),
      },
      {
        accessorKey: "excludePriceAbove",
        header: "Exclude Above",
        size: 80,
        Cell: renderPriceFieldCell("excludePriceAbove"),
      },
      {
        accessorKey: "baseMinPrice",
        header: "Incremental Minimum Price",
        size: 100,
        Cell: renderBaseMinPriceCell,
      },
      {
        accessorKey: "priceIncrement",
        header: "Incremental Cent Amount",
        size: 100,
        Cell: renderIncrementalPriceCell,
      },
    ],
    [products, disableEditing, deleteProduct, runStatus]
  );

  const table = useMaterialReactTable({
    ...tableTheme,
    columns,
    data: products,
    initialState: {
      density: "compact",
      sorting: [{ id: "productId", desc: false }],
    },
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableColumnActions: false,
    enableColumnOrdering: false,
    enableSorting: false,
    editDisplayMode: "cell",
    enableEditing: false,
    enablePagination: false,
    enableCellActions: false,
    enableRowOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
      sx: { whiteSpace: "nowrap", textOverflow: "ellipsis" },
    },
    muiTableBodyCellProps: { align: "center" },
    layoutMode: "semantic",
    muiTableContainerProps: {
      sx: {
        maxHeight: "20vh",
      },
    },
  });

  return <MaterialReactTable table={table} />;
};

export default ProductSettingsTable;
