import { z } from "zod";
import {
  MarketMoveMetaDataSchema,
  marketMoveMetaDataSchema,
  MarketMoveProductConfigSchema,
  MarketMoveRunSchema,
  marketMoveRunSchema,
  MarketMoveStoreListSchema,
  PreviewDetailsSchema,
  previewDetailsSchema,
} from "../schema/schemas";
import { formatISODate } from "./textUtils";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";



export function getDefaults<T extends z.ZodTypeAny>(
  schema: z.AnyZodObject | z.ZodEffects<any>
): z.infer<T> {
  if (schema instanceof z.ZodEffects) {
    return schema.innerType() instanceof z.ZodEffects
      ? getDefaults(schema.innerType())
      : getDefaults(z.ZodObject.create(schema.innerType().shape));
  }

  function getDefaultValue(schema: z.ZodTypeAny): unknown {
    if (schema instanceof z.ZodDefault) return schema._def.defaultValue();
    if (schema instanceof z.ZodArray) return [];
    if (schema instanceof z.ZodString) return "";
    if (schema instanceof z.ZodObject) return getDefaults(schema);
    return "innerType" in schema._def
      ? getDefaultValue(schema._def.innerType)
      : undefined;
  }

  return Object.fromEntries(
    Object.entries(schema.shape).map(([key, value]) => [
      key,
      getDefaultValue(value as z.ZodTypeAny),
    ])
  );
}

export const getCSTDate = (date: dayjs.Dayjs): dayjs.Dayjs => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  console.debug("getCSTDate - date", date);
  
  const CST_TIME_ZONE = "America/Chicago";
  const cstDate = date.tz(CST_TIME_ZONE);
  console.debug("getCSTDate - cstDate", cstDate);
  return cstDate;
}

export const generateDefaultMarketMove = (): MarketMoveMetaDataSchema => {
  const tomorrow = getCSTDate(dayjs().add(1, 'day'));
  const roundedDate = tomorrow.add(1, 'hour');

  const startDate = tomorrow.format('YYYY-MM-DD HH:mm:ss')
  const endDate = roundedDate.format('YYYY-MM-DD HH:mm:ss')
  console.debug("generateDefaultMarketMove - startDate", startDate);
  console.debug("generateDefaultMarketMove - endDate", endDate);

  return {
    ...getDefaults(marketMoveMetaDataSchema),
    marketMoveId: null,
    marketMoveName: '',
    startDate,
    endDate,
  }
};

export const generateDefaultPreviewDetails = (): PreviewDetailsSchema => ({
  ...getDefaults(previewDetailsSchema),
  marketMoveId: "",
  marketMoveName: "",
  marketMoveStores: [],
  storeProductConfig: [],
  marketMoveProductConfigs: [],
});

export const generateDefaultMarketMoveRun = (): MarketMoveRunSchema => ({
  ...getDefaults(marketMoveRunSchema),
});

export const areConfigsIdentical = (
  config1: MarketMoveProductConfigSchema[],
  config2: MarketMoveProductConfigSchema[]
): boolean => {
  const keysToIgnore = ["productConfigId", "mmRunProdConfigId", "marketMoveRunId"];

  if (config1.length !== config2.length) {
    return false;
  }

  const result = config1.every((item1, index) => {
    const item2 = config2[index];
    return Object.keys(item1).every((key) => {
      if (keysToIgnore.includes(key)) return true;

      const val1 = item1[key];
      const val2 = item2[key];

      if (val1 === val2) return true;
      if (val1 == null && val2 == null) return true;
      if (Number.isNaN(val1) && Number.isNaN(val2)) return true;

      return false;
    });
  });

  return result;
};

export const areStoreListsIdentical = (
  list1: MarketMoveStoreListSchema[],
  list2: MarketMoveStoreListSchema[]
): boolean => {
  const keysToIgnore = ['flag', 'storeListId'];
  
  if (list1.length !== list2.length) {
    return false;
  }

  const result = list1.every((item1, index) => {
    const item2 = list2[index];
    return Object.keys(item1).every((key) => {
      if (keysToIgnore.includes(key)) return true;

      const val1 = item1[key];
      const val2 = item2[key];

      if (val1 === val2) return true;
      if (val1 == null && val2 == null) return true;
      if (Number.isNaN(val1) && Number.isNaN(val2)) return true;

      return false;
    });
  });

  return result;
};