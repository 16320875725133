import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import UndoIcon from "@mui/icons-material/Undo"; // Changed import from DeleteIcon to UndoIcon
import {
  Alert,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Skeleton,
  Snackbar,
  Tooltip
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useShallow } from "zustand/react/shallow";
import {
  useCreateMarketMove,
  useGetMMList,
  useUpdateMarketMove,
} from "../../../hooks/marketMoveMetaDataApiHooks";
import useShouldSave from "../../../hooks/useShouldSave";
import { RunStatus } from "../../../interfaces/enums";
import {
  useMarketMoveMetaDataMethods,
  useMarketMoveRunMethods
} from "../../../store/formStore";
import useMarketMoveStore from "../../../store/marketMoveStore";
import useDialogStore from "../../../store/useDialogStore";
import { generateDefaultMarketMove } from "../../../utils/marketMoveUtils";
import { EndDateUpdateBox } from "../../shared/EndDateUpdate";
import GlobalDialog from "../../shared/GlobalDialog";
import LifeCycleStepper from "../../shared/LifeCycleStepper";
import ZonedDateTimePicker from "../../shared/ZonedDateTimePicker";
import MarketMoveNameField from "./MarketMoveNameField";
import StoreListDialog from "./storeList/StoreListDialog";

const MarketMoveSettings: React.FC = () => {
  const { isError, isLoading, error } = useGetMMList();
  const {
    setSelectedMarketMoveId,
    incrementPreviewDetailsFetchReq,
    incrementMMMetaDataSaveReq,
  } = useMarketMoveStore();

  const { openGlobalDialog, closeGlobalDialog } = useDialogStore(
    useShallow((state) => ({
      openGlobalDialog: state.openDialog,
      closeGlobalDialog: state.closeDialog,
    }))
  );

  const [openStoreListDialog, setOpenStoreListDialog] = useState(false);


  const createMarketMoveMetaData = useCreateMarketMove();
  const updateMarketMoveMetaData = useUpdateMarketMove();
  const {
    watch: marketMoveRunWatch,
    formState: { defaultValues: marketMoveRunDefaultValues },
  } = useMarketMoveRunMethods();

  const {
    trigger,
    watch: marketMoveMetaDataWatch,
    reset: resetForm,
  } = useMarketMoveMetaDataMethods();

  const runStatus = marketMoveRunWatch("marketMoveRun.runStatus");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [showErrorIcon, setShowErrorIcon] = useState(false);

  // Use the custom hook to determine if we should save and get the reason
  const { shouldSave, dirtyFieldsTooltip } = useShouldSave();

  const handleSave = () => {
    if (runStatus === RunStatus.INPROGRESS) {
      openGlobalDialog({
        content: <EndDateUpdateBox originalEndDate={marketMoveRunDefaultValues.marketMoveRun?.endDate} newEndDate={endDate} />,
        acceptCallback: () => handleDialogSave(RunStatus.INPROGRESS),
        acceptButtonText: "Yes, Save",
        discardButtonText: "No, Cancel",
        actions: null,
      });
    } else {
      handleDialogSave();
    }
  };

  const { trigger: useFormTrigger } = useMarketMoveMetaDataMethods(); // Use useFormContext to access trigger

  // Watch the endDate field
  const endDate = marketMoveMetaDataWatch("endDate");

  useEffect(() => {
    if (isError) setOpenSnackbar(true);
  }, [isError]);

  useEffect(() => {
    handleErrorIconVisibility();
  }, [createMarketMoveMetaData.isError, updateMarketMoveMetaData.isError]);

  useEffect(() => {
    if (endDate) {
      useFormTrigger("startDate"); // Trigger validation on startDate when endDate changes
    }
  }, [endDate, useFormTrigger]);

  const handleErrorIconVisibility = () => {
    if (createMarketMoveMetaData.isError || updateMarketMoveMetaData.isError) {
      setShowErrorIcon(true);
      const timer = setTimeout(() => setShowErrorIcon(false), 3000);
      return () => clearTimeout(timer);
    }
  };

  // useEffect(() => {
  //   openGlobalDialog({
  //     title: "Discard Changes",
  //     content: <div>Do you want to discard the changes?</div>,
  //     acceptCallback: handleDiscard,
  //     discardCallback: () => closeGlobalDialog(),
  //     acceptButtonText: "Yes, Discard", // Custom text for accept button
  //     discardButtonText: "No, Keep", // Custom text for discard button
  //     actions: null,
  //   });
  // }, []);

  const handleCloseSnackbar = () => setOpenSnackbar(false);

  const handleCancel = () => {
    if (shouldSave) {
      openGlobalDialog({
        content: <div>Do you want to discard the changes?</div>,
        acceptCallback: handleDiscard,
        discardCallback: () => closeGlobalDialog(),
        acceptButtonText: "Yes, Discard", // Custom text for accept button
        discardButtonText: "No, Keep", // Custom text for discard button
        actions: null,
      });
    } else {
      resetForm(null);
    }
  };

  const handleDialogSave = (newStatus: RunStatus = RunStatus.DRAFT) => {
    incrementPreviewDetailsFetchReq(true, () => {
      trigger().then((isValid) => {
        if (isValid) {
          incrementMMMetaDataSaveReq(newStatus, true);
        } else {
          enqueueSnackbar("Please fix the errors in the form", {
            variant: "error",
          });
        }
      });
    });
    closeGlobalDialog();
  };


  const handleDiscard = () => {
    resetForm(generateDefaultMarketMove());
    setSelectedMarketMoveId(null);
    closeGlobalDialog();
  };

  const createDateField = (
    label: string,
    valueKey: "startDate" | "endDate"
  ) => <ZonedDateTimePicker name={valueKey} />;

  const renderHeader = () => (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mb={1}
    >
      <Tooltip title={dirtyFieldsTooltip}>
        <span>
          <IconButton
            aria-label="Save changes"
            sx={{ color: shouldSave ? "green" : "gray" }}
            disabled={
              !shouldSave ||
              createMarketMoveMetaData.isLoading ||
              updateMarketMoveMetaData.isLoading
            }
            onClick={handleSave}
          >
            {createMarketMoveMetaData.isLoading ||
            updateMarketMoveMetaData.isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : showErrorIcon ? (
              <CloseIcon sx={{ color: "red" }} />
            ) : (
              <SaveIcon />
            )}
          </IconButton>
        </span>
      </Tooltip>
      <LifeCycleStepper runStatus={runStatus} />
      <Tooltip title="Undo changes">
        <span>
          <IconButton
            aria-label="Undo changes"
            sx={{ color: shouldSave ? "red" : "gray" }}
            onClick={handleCancel}
            disabled={
              !shouldSave ||
              isLoading ||
              createMarketMoveMetaData.isLoading ||
              updateMarketMoveMetaData.isLoading
            }
          >
            <UndoIcon />
          </IconButton>
        </span>
      </Tooltip>
    </Box>
  );

  const renderContent = () => (
    <>
      {/* <ErrorDisplay errors={marketMoveMetaDataFormState.errors} /> */}

      <Grid container>
        <Grid item xs={12} mb={2}>
          <MarketMoveNameField />
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={3}>
          {createDateField("Start Date (CST)", "startDate")}
        </Grid>
        <Grid item xs={3}>
          {createDateField("End Date (CST)", "endDate")}
        </Grid>
        <Grid item xs={6}>
          <StoreListDialog open={openStoreListDialog} setOpen={setOpenStoreListDialog} onClose={() => setOpenStoreListDialog(false)} />
        </Grid>
      </Grid>
    </>
  );

  const renderSkeleton = () => (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={40} />
      </Grid>
      <Grid item xs={3}>
        <Skeleton variant="rectangular" height={56} />
      </Grid>
      <Grid item xs={3}>
        <Skeleton variant="rectangular" height={56} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant="rectangular" height={200} />
      </Grid>
    </Grid>
  );

  return (
    <>
      <Grid item xs={12} className="marketMoveSettings">
        <Box borderRadius={2}>
          {renderHeader()}
          {isLoading ? renderSkeleton() : renderContent()}
        </Box>
      </Grid>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="error">
          {error?.message || "An error occurred while loading market moves."}
        </Alert>
      </Snackbar>
      <GlobalDialog />
    </>
  );
};

export default MarketMoveSettings;
