import React from 'react';
import { Tooltip, Typography } from "@mui/material";

interface TruncatedMarketMoveNameProps {
  name: string;
  maxLength?: number;
}

const TruncatedMarketMoveName: React.FC<TruncatedMarketMoveNameProps> = ({ name, maxLength = 20 }) => {
  const truncatedName =
    name.length > maxLength ? `${name.substring(0, maxLength)}...` : name;

  return (
    <Tooltip title={name} disableHoverListener={name.length <= maxLength}>
      <Typography variant="body2">{truncatedName}</Typography>
    </Tooltip>
  );
};

export default TruncatedMarketMoveName;
