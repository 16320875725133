import { Box, Container } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import PastExecutionsView from "./component/views/PastExecutionsView";
import EditMarketMoveView from "./component/views/EditMarketMoveView";
import Recap from "./component/views/Recap/Recap";
import useGeneralStore from "./store/generalStore";

const queryClient = new QueryClient();

const App = ({ customProps }: { customProps: any }) => {
  const { showRecap, showPastMarketMoves } = useGeneralStore();


  return (
      <QueryClientProvider client={queryClient}>
        <Container maxWidth={false}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <div hidden={showRecap || showPastMarketMoves}>
              {!showPastMarketMoves && <EditMarketMoveView />}
            </div>
            {showRecap && <Recap />}
            {showPastMarketMoves && <PastExecutionsView />}
          </Box>
        </Container>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
  );
};

export default App;