import { Box, ThemeProvider, Typography, createTheme } from "@mui/material";
import { SnackbarProvider } from "notistack";
import React, { useState } from "react";
import App from "../../App";
import { usePermissions } from "../../hooks/usePermissions";
import { useMarketMoveMetaDataMethods } from "../../store/formStore";
import { themeDef } from "../../theme/theme";
import UnsavedChangesDialog from "../shared/UnsavedChanges";
import {ListViewSelectedStore} from "../../store/generalStore";

// Define ActionCardProps interface
interface ActionCardProps {
  listviewSelectedStores: ListViewSelectedStore[];
  extractedStoreCount: number;
}

interface ActionCardContentProps {
  selectedProps: ActionCardProps | undefined;
  customProps: any;
}

const ActionCardContent: React.FC<ActionCardContentProps> = ({
  selectedProps,
  customProps,
}) => {

  const { hasPermission } = usePermissions();
  const theme = createTheme(themeDef);
  const marketMoveMetaDataMethods = useMarketMoveMetaDataMethods();
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleClose = () => {
    if (marketMoveMetaDataMethods?.formState?.isDirty) {
      setDialogOpen(true);
    } else {
      customProps.compRefClose();
    }
  };

  if (!selectedProps) return null;

  if (!hasPermission) {
    return (
      <Box className="market-move-permissions-popup">
        <Typography variant="h5">
          You do not have permission to access market move
        </Typography>
      </Box>
    );
  }

  return (
      <Box sx={{ height: "100%", overflow: "auto", padding: "0 8px" }}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          autoHideDuration={4000}
          preventDuplicate
        >
          <ThemeProvider theme={theme}>
            <App customProps={customProps} />
            <UnsavedChangesDialog
              isOpen={dialogOpen}
              onClose={handleClose}
              onDiscard={() => setDialogOpen(false)}
              showSaveButton={false}
            />
          </ThemeProvider>
        </SnackbarProvider>
      </Box>
  );
};

export default ActionCardContent;