import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Button, Tooltip, useTheme } from "@mui/material";

interface LinkedProductsToggleProps {
  showLinkedProducts: boolean;
  setShowLinkedProducts: (show: boolean) => void;
}

const LinkedProductsToggle: React.FC<LinkedProductsToggleProps> = ({ 
  showLinkedProducts, 
  setShowLinkedProducts 
}) => {
  const theme = useTheme();

  const buttonColor = showLinkedProducts ? theme.palette.info.main : theme.palette.text.secondary;
  const nextColor = showLinkedProducts ? theme.palette.text.secondary : theme.palette.info.main;

  return (
    <Tooltip title={`Linked products are currently ${showLinkedProducts ? 'visible' : 'hidden'}. Click to ${showLinkedProducts ? 'hide' : 'show'} them.`}>
      <Button
        onClick={() => setShowLinkedProducts(!showLinkedProducts)}
        startIcon={
          showLinkedProducts ? (
            <VisibilityIcon sx={{ color: buttonColor }} />
          ) : (
            <VisibilityOffIcon sx={{ color: buttonColor }} />
          )
        }
        variant="outlined"
        size="small"
        sx={{
          color: nextColor,
          borderColor: buttonColor,
          '&:hover': {
            borderColor: nextColor,
            backgroundColor: theme.palette.action.hover,
          },
        }}
      >
        {showLinkedProducts ? "Hide" : "Show"} Linked Products
      </Button>
    </Tooltip>
  );
};

export default LinkedProductsToggle;