import DeltaIcon from "@mui/icons-material/ChangeHistory";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import { Button, Tooltip, useTheme } from "@mui/material";
import { PriceMode } from "../../interfaces/enums";

const SortModeSelect: React.FC<{
  sortMode: PriceMode;
  handleSortModeChange: () => void;
}> = ({ sortMode, handleSortModeChange }) => {
  const theme = useTheme();

  const isSortByPrice = sortMode === PriceMode.ABSOLUTE;
  const buttonColor = isSortByPrice ? theme.palette.success.main : theme.palette.text.secondary;
  const nextColor = isSortByPrice ? theme.palette.text.secondary : theme.palette.success.main;

  return (
    <Tooltip title={`Currently sorting by ${isSortByPrice ? 'price' : 'magnitude'}. Click to sort by ${isSortByPrice ? 'magnitude' : 'price'}.`}>
      <Button
        onClick={handleSortModeChange}
        startIcon={
          isSortByPrice ? (
            <PriceChangeIcon sx={{ color: buttonColor }} />
          ) : (
            <DeltaIcon sx={{ color: buttonColor }} />
          )
        }
        variant="outlined"
        size="small"
        sx={{
          color: nextColor,
          borderColor: buttonColor,
          '&:hover': {
            borderColor: nextColor,
            backgroundColor: theme.palette.action.hover,
          },
        }}
      >
        Sort by {isSortByPrice ? 'Magnitude' : 'Price'}
      </Button>
    </Tooltip>
  );
};

export default SortModeSelect;