import {
  CancelScheduleSend as CancelScheduleSendIcon,
  DoneAll as DoneAllIcon,
  EditNote as EditNoteIcon,
  ScheduleSend as ScheduleSendIcon,
  Send as SendIcon
} from "@mui/icons-material";
import { Box, ListItemButton, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import { RunStatus } from "../../../interfaces/enums";
import { MarketMoveMetaDataSchema } from "../../../schema/schemas";
import TruncatedMarketMoveName from "./TruncatedMarketMoveName";

interface MarketMoveListItemProps {
  move: MarketMoveMetaDataSchema;
  selectedMarketMoveId: string | null;
  handleMarketMoveChange: (
    event: React.MouseEvent<HTMLElement>,
    newMarketMoveId: string | null
  ) => void;
  handleMenuOpen: (
    event: React.MouseEvent<HTMLElement>,
    moveId: string
  ) => void;
}

const formatDate = (dateString: string | undefined): string => {
  if (!dateString) return "";
  const date = new Date(dateString);
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

const getMarketMoveStyle = (
  move: MarketMoveMetaDataSchema
): React.CSSProperties => {
  const today = new Date();
  const startDate = new Date(move.startDate ?? "");
  const endDate = new Date(move.endDate ?? "");

  if (today >= startDate && today <= endDate) {
    return { fontWeight: "bold", backgroundColor: "" };
  }
  return today < startDate
    ? { backgroundColor: "" }
    : { color: "#6c757d", backgroundColor: "", opacity: 0.5 };
};

const getStatusIcon = (
  move: MarketMoveMetaDataSchema | undefined | null
): { icon: React.ReactElement; tooltip: string } => {
  switch (move?.runStatus) {
    case RunStatus.SIGNEDOFF:
      return {
        icon: <ScheduleSendIcon color="primary" fontSize="small" />,
        tooltip: "Submitted",
      };
    case RunStatus.INPROGRESS:
      return {
        icon: <SendIcon color="secondary" fontSize="small" />,
        tooltip: "In Progress",
      };
    case RunStatus.EXECUTED:
      return {
        icon: <DoneAllIcon color="success" fontSize="small" />,
        tooltip: "Executed",
      };
    case RunStatus.CANCELLED:
      return {
        icon: <CancelScheduleSendIcon color="error" fontSize="small" />,
        tooltip: "Cancelled",
      };
    case RunStatus.DRAFT:
    default:
      return {
        icon: <EditNoteIcon color="action" fontSize="small" />,
        tooltip: "Draft",
      };
  }
};

const getHoverInfo = (move: MarketMoveMetaDataSchema): string => {
  return `ID: ${move.marketMoveId || "N/A"}
Run ID: ${move.marketMoveRunId || "N/A"}
Modified: ${move.modifiedDate || "N/A"}
Created: ${move.createdDate || "N/A"}`;
};

const MarketMoveListItem: React.FC<MarketMoveListItemProps> = ({
  move,
  selectedMarketMoveId,
  handleMarketMoveChange,
  handleMenuOpen,
}) => {
  return (
    <Tooltip title={getHoverInfo(move)} placement="right" arrow>
      <ListItemButton
        selected={selectedMarketMoveId === move.marketMoveId}
        onClick={(event) => handleMarketMoveChange(event, move.marketMoveId)}
        sx={{
          ...getMarketMoveStyle(move),
          "&.Mui-selected": {
            ...getMarketMoveStyle(move),
            border: "2px solid green",
          },
          "&:hover": {
            opacity: 0.8,
            ...getMarketMoveStyle(move),
          },
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Tooltip title={getStatusIcon(move).tooltip} placement="top" arrow>
          <Box>{getStatusIcon(move).icon}</Box>
        </Tooltip>
        <Box sx={{ flexGrow: 1, ml: 2, mr: 2 }}>
          <TruncatedMarketMoveName name={move.marketMoveName} />
          <Stack
            direction="row"
            spacing={0.5}
            alignItems="center"
            sx={{ mt: 0.5 }}
          >
            {move.createdBy && (
              <>
                <Typography variant="caption" color="textSecondary">
                  {move.createdBy}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  •
                </Typography>
              </>
            )}
            <Typography variant="caption" color="textSecondary">
              {formatDate(move.startDate)} - {formatDate(move.endDate)}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              •
            </Typography>
            <Typography variant="caption" color="textSecondary">
              Stores: {move.storeCount || 0}
            </Typography>
          </Stack>
        </Box>
      </ListItemButton>
    </Tooltip>
  );
};

export default MarketMoveListItem;
