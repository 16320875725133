import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { fetchPreviewDetails } from "../api/previewDetailsApi";
import {
  MarketMoveRunDetailSchema,
  PreviewDetailsSchema
} from "../schema/schemas";

export const useGetPreviewDetails = (
  previewDetails: PreviewDetailsSchema,
  options?: Omit<
    UseQueryOptions<MarketMoveRunDetailSchema[], Error>,
    "queryKey" | "queryFn"
  >
) => {
  return useQuery<MarketMoveRunDetailSchema[], Error>(
    ["previewDetails", previewDetails],
    () => fetchPreviewDetails(previewDetails),
    {
      retry: 1,
      ...options,
    }
  );
};
