import React, { useMemo } from "react";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Tabs,
  Tab,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import { Store, AttachMoney } from "@mui/icons-material";
import { MarketMoveRunDetailSchema, MarketMoveRunSchema, MarketMoveProductConfigSchema } from "../../../schema/schemas";
import { PriceMode } from "../../../interfaces/enums";
import products from "../../../constants/products.json";

interface ProductCardProps {
  productId: string;
  recapDetails: MarketMoveRunDetailSchema[];
  selectedMarketMove: MarketMoveRunSchema;
}

const ProductCard: React.FC<ProductCardProps> = ({
  productId,
  recapDetails,
  selectedMarketMove,
}) => {
  const [tabValue, setTabValue] = React.useState(0);
  const productConfig = selectedMarketMove.marketMoveRunProductConfigs?.find(
    (p) => p.productId === productId
  );

  const productDetails = useMemo(
    () => recapDetails.filter((detail) => detail.productId === productId),
    [recapDetails, productId]
  );

  const averageDelta = useMemo(() => {
    const deltas = productDetails.map(detail => detail.delta).filter(delta => delta !== null);
    const totalDelta = deltas.reduce((acc, delta) => acc + (delta ?? 0), 0);
    return deltas.length > 0 ? totalDelta / deltas.length : 0;
  }, [productDetails]);

  const productName = useMemo(() => {
    const product = products.find(p => p.id === productId);
    return product ? product.name : "Unknown";
  }, [productId]);

  if (productDetails.length === 0 || !productConfig) return null;

  const totalStoresCount = productDetails.length;
  const affectedStoresCount = productDetails.filter(
    (detail) => detail.includeFlag && detail.delta !== 0 && !detail.exclusionReason
  ).length;

  return (
    <Grid item xs={12} sm={6} md={4} lg={3} key={productId}>
      <Card variant="outlined">
        <CardContent sx={{ p: 1 }}>
          <Typography variant="subtitle2" sx={{ mb: 0.5, fontSize: '0.85rem' }}>
            {productId} - {productName} ({productConfig.priceMode})
          </Typography>
          <Grid container spacing={0.5} sx={{ mb: 0.5 }}>
            <GridItem 
              label="Affected" 
              value={`${affectedStoresCount}/${totalStoresCount}`} 
            />
            <GridItem 
              label="Avg Delta" 
              value={averageDelta.toFixed(2)} 
            />
          </Grid>
          <Tabs
            value={tabValue}
            onChange={(_, newValue) => setTabValue(newValue)}
            variant="fullWidth"
            sx={{ mb: 0.5, minHeight: '32px' }}
            TabIndicatorProps={{ style: { height: '2px' } }}
          >
            <Tab label="Config" sx={{ minWidth: 0, px: 0.5, fontSize: '0.8rem', minHeight: '32px' }} />
            <Tab label="Overrides" sx={{ minWidth: 0, px: 0.5, fontSize: '0.8rem', minHeight: '32px' }} />
          </Tabs>
          <Box sx={{ p: 0.5 }}>
            {tabValue === 0 ? (
              <PriceConfigInfo productConfig={productConfig} />
            ) : (
              <PriceOverridesInfo productDetails={productDetails} />
            )}
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

interface PriceConfigInfoProps {
  productConfig: MarketMoveProductConfigSchema;
}

const PriceConfigInfo: React.FC<PriceConfigInfoProps> = ({ productConfig }) => {
  return (
    <Grid container spacing={0.5}>
      <GridItem label="Base Min" value={productConfig.baseMinPrice} />
      <GridItem label="Base Max" value={productConfig.baseMaxPrice} />
      <GridItem label="Excl Below" value={productConfig.excludePriceBelow} />
      <GridItem label="Excl Above" value={productConfig.excludePriceAbove} />
      <GridItem
        label={productConfig.priceMode === PriceMode.ABSOLUTE ? "Abs Price" : "Increment"}
        value={productConfig.priceMode === PriceMode.ABSOLUTE ? productConfig.absPrice : productConfig.priceIncrement}
      />
    </Grid>
  );
};

const GridItem: React.FC<{ label: string; value: number | string | null | undefined }> = ({ label, value }) => (
  <Grid item xs={6}>
    <Typography variant="caption" display="block" sx={{ fontSize: '0.75rem' }}>{label}:</Typography>
    <Typography variant="body2" sx={{ fontSize: '0.85rem' }}>{value ?? "N/A"}</Typography>
  </Grid>
);

interface PriceOverridesInfoProps {
  productDetails: MarketMoveRunDetailSchema[];
}

const PriceOverridesInfo: React.FC<PriceOverridesInfoProps> = ({ productDetails }) => {
  const overrides = productDetails.filter((detail) => detail.manualPriceOverrideFlag);

  return (
    <Box sx={{ maxHeight: 150, overflowY: 'auto' }}>
      {overrides.length > 0 ? (
        <List dense disablePadding>
          {overrides.map((detail) => (
            <ListItem key={detail.storeId} sx={{ py: 0.5 }}>
              <ListItemIcon sx={{ minWidth: 28 }}>
                <Store fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="body2" component="span">
                      <strong>{detail.storeId}</strong>
                    </Typography>
                    <Box display="flex" alignItems="center">
                      <AttachMoney fontSize="small" sx={{ mr: 0.5 }} />
                      <Typography variant="body2" component="span">
                        {detail.manualPrice ?? detail.newPrice ?? "N/A"}
                      </Typography>
                    </Box>
                  </Box>
                }
              />
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography variant="body2" color="textSecondary">
          No price overrides
        </Typography>
      )}
    </Box>
  );
};

export default ProductCard;